<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <div id="user-profile">
      <!-- profile header -->
      <div class="row">
        <div class="col-12">
          <div class="card profile-header mb-2">
            <div class="position-relative">
              <!-- profile picture -->
              <div class="profile-img-container d-flex align-items-center" style="padding:15px;">
                <div class="profile-img">
                  <img src="assets/images/logo/logo.png" class="rounded img-fluid" alt="Card image" />
                </div>
                <!-- profile title -->
                <div class="profile-title ml-3">
                  <h2 class="">Hola, {{nombre_usuario}} </h2>
                  <p class="">Bienvenido a nuestro panel administrativo de Omega</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!--/ profile header -->
    </div>
    <!-- Basic Alerts start -->
    <section id="bootstrap-select">
      <div class="row">
        <div class="col-xl-12 col-md-12 col-12" >
          <div class="card ">
            <div class="card-body">

              <form class="auth-login-form mt-2" [formGroup]="loginForm">
                <div class="row">
                  <div class="col-sm-6 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-email">Email</label>
                      <input
                        type="text"
                        formControlName="email"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                        placeholder=""
                        aria-describedby="form-email"
                        autofocus=""
                        tabindex="1"
                        readonly
                      />
                      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email obligatorio</div>
                        <div *ngIf="f.email.errors.email">El email debe ser una dirección de correo electrónico válida</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-password">Contraseña</label>
                      <input
                        type="password"
                        formControlName="password"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                        placeholder=""
                        aria-describedby="form-password"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Contraseña obligatoria</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-usuario">Usuario</label>
                      <input
                        type="text"
                        formControlName="usuario"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.usuario.errors }"
                        placeholder=""
                        aria-describedby="form-usuario"
                        autofocus=""
                        tabindex="1"
                        readonly
                      />
                      <div *ngIf="submitted && f.usuario.errors" class="invalid-feedback">
                        <div *ngIf="f.usuario.errors.required">Usuario obligatorio</div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-sm-6 col-12" [ngClass]="{ 'hide': selectedrol!='Asociado' }">
                    <label>Socio</label>
                    <div class="form-group">
                      <ng-select [items]="socios" formControlName="socio" bindValue="SocioID" bindLabel="Nombre" class="form-control" [readonly]="true"> </ng-select>
                    </div>
                    
                  </div>
                  <div class="col-sm-12 col-12 row">
                    <div class="col-sm-2 col-12">
                      <br>
                      <button [disabled]="loading" class="btn btn-info btn-block" tabindex="4" rippleEffect (click)="edit()">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Editar 
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              
                <!--<div class="row" *ngIf="selectedrol=='Asociado' && mostrardocumento">
                  <div class="col-sm-12 col-12 row"  >
                    <div class="col-12" >
                      <hr>
                      <h4 class="card-title">Documentos</h4>
                    </div>
                    <div class="col-12 row">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Titulo</th>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let arc of archivos">
                              <td>{{arc.titulo}}</td>
                              <td>
                                <button class="btn btn-info btn-block" tabindex="4" rippleEffect (click)="verfile(arc)">
                                  Ver
                                </button>
                              </td>
                              <td>
                                <button class="btn btn-danger btn-block" tabindex="4" rippleEffect (click)="deletefile(arc._id)">
                                  Eliminar
                                </button>
                              </td>
                            </tr>
                            <tr *ngIf="archivos.length==0">
                              <td colspan="3" style="text-align:center;">Sin documentos cargados</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    
                    
                    <div class="col-12" >
                      <hr>
                      <h4 class="card-title">Nuevo Documento</h4>
                    </div>
                    <div class="col-sm-5 col-12" >
                      <div class="form-group">
                        <label>Titulo</label>
                        <input
                        type="text"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted2 && titulo=='' }"
                         [(ngModel)]="titulo"
                        />

                        <div *ngIf="submitted2 && !titulo" class="invalid-feedback">
                          <div *ngIf="!titulo">Titulo obligatorio</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-5 col-12" >
                      <div class="form-group">
                        <label>Archivo</label>
                        <input
                        type="file"
                        class="form-control"
                        placeholder=""
                        aria-describedby="form-archivo"
                        autofocus=""
                        tabindex="1"
                        [ngClass]="{ 'is-invalid': submitted2 && archivo=='' }"
                        [(ngModel)]="archivo"
                        (change)="onFileSelected($event)"
                        />
                        <div *ngIf="submitted2 && !archivo" class="invalid-feedback">
                          <div *ngIf="!archivo">Archivo obligatorio</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2 col-12">
                      <br>
                      <button [disabled]="loading2" class="btn btn-primary btn-block" tabindex="4" rippleEffect (click)="addfile()">
                        <span *ngIf="loading2" class="spinner-border spinner-border-sm mr-1"></span>Subir 
                      </button>
                    </div>
                  </div>
                </div>-->
              
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
