import { CoreMenu } from '@core/types'
const user = JSON.parse(localStorage.getItem('currentUser') || '{}');
let confi_menu = [];
if(user.rol=='Administrador'){
  confi_menu = [
    {
      id: 'home',
      title: 'Inicio',
      translate: 'MENU.HOME',
      type: 'item',
      icon: 'grid',
      url: 'home'
    },
    {
      id: 'informe_diario',
      title: 'Informe Diario',
      translate: 'Informe Diario',
      type: 'collapsible',
      icon: 'clipboard',
      children: [
        {
          id: 'reporte1',
          title: 'Consultar',
          translate: 'Consultar',
          type: 'item',
          icon: 'list',
          url: 'informe_diario'
        }
      ]
    },
    {
      id: 'anticipo',
      title: 'Anticipos',
      translate: 'Anticipos',
      type: 'collapsible',
      icon: 'align-left',
      children: [
        {
          id: 'reporte1',
          title: 'Consultas',
          translate: 'Consultas',
          type: 'item',
          icon: 'list',
          url: 'anticipo'
        }
      ]
    },
    {
      id: 'egresos',
      title: 'Egresos',
      translate: 'Egresos',
      type: 'collapsible',
      icon: 'trending-down',
      children: [
        {
          id: 'reporte2',
          title: 'Consultas',
          translate: 'Consultas',
          type: 'item',
          icon: 'list',
          url: 'egresos'
        }
      ]
    },
    {
      id: 'tiquetes',
      title: 'Tiquetes',
      translate: 'Tiquetes',
      type: 'collapsible',
      icon: 'bookmark',
      children: [
        {
          id: 'reporte3',
          title: 'Consultas',
          translate: 'Consultas',
          type: 'item',
          icon: 'list',
          url: 'tiquetes'
        }
      ]
    },
    {
      id: 'ventas',
      title: 'Ventas',
      translate: 'Ventas',
      type: 'collapsible',
      icon: 'trending-up',
      children: [
        {
          id: 'reporte1',
          title: 'Reporte',
          translate: 'Reporte',
          type: 'item',
          icon: 'list',
          url: 'reporte_ventas'
        }
      ]
    },
    {
      id: 'producidos',
      title: 'Producido',
      translate: 'Producido',
      type: 'collapsible',
      icon: 'divide-circle',
      children: [
        {
          id: 'reporte1',
          title: 'Reporte',
          translate: 'Reporte',
          type: 'item',
          icon: 'list',
          url: 'producidos'
        }
      ]
    },
    {
      id: 'anual',
      title: 'Anual',
      translate: 'Anual',
      type: 'collapsible',
      icon: 'calendar',
      children: [
        {
          id: 'reporte2',
          title: 'Reporte',
          translate: 'Reporte',
          type: 'item',
          icon: 'list',
          url: 'reporte_fin_anio'
        }
      ]
    },
    {
      id: 'mensual',
      title: 'Diario Mensual',
      translate: 'Diario Mensual',
      type: 'collapsible',
      icon: 'layout',
      children: [
        {
          id: 'reporte_mensual',
          title: 'Reporte',
          translate: 'Reporte',
          type: 'item',
          icon: 'list',
          url: 'reporte_mensual'
        }
      ]
    },
    {
      id: 'agencia',
      title: 'Agencia',
      translate: 'Agencia',
      type: 'collapsible',
      icon: 'home',
      children: [
        {
          id: 'reporte3',
          title: 'Reporte',
          translate: 'Reporte',
          type: 'item',
          icon: 'list',
          url: 'reporte_agencia'
        }
      ]
    },
    {
      id: 'vehiculo',
      title: 'Buses',
      translate: 'Buses',
      type: 'collapsible',
      icon: 'truck',
      children: [
        {
          id: 'reporte4',
          title: 'Reporte',
          translate: 'Reporte',
          type: 'item',
          icon: 'list',
          url: 'reporte_vehiculo'
        },
        {
          id: 'reporte5',
          title: 'Reporte Detallado',
          translate: 'Reporte Detallado',
          type: 'item',
          icon: 'list',
          url: 'reporte_vehiculo_detallado'
        },
        {
          id: 'vehiculos',
          title: 'Configuración',
          translate: 'Configuración',
          type: 'item',
          icon: 'list',
          url: 'vehiculos'
        }
      ]
    },
    {
      id: 'textoPlano',
      title: 'Archivos Planos',
      translate: 'Archivos Planos',
      type: 'collapsible',
      icon: 'file',
      children: [
        {
          id: 'archivoPlano',
          title: 'Lista',
          translate: 'Lista',
          type: 'item',
          icon: 'list',
          url: 'archivoPlano'
        }
      ]
    },
    {
      id: 'ingresos',
      title: 'Ingresos',
      translate: 'Ingresos',
      type: 'collapsible',
      icon: 'dollar-sign',
      children: [
        {
          id: 'reportei1',
          title: 'Buses',
          translate: 'Buses',
          type: 'item',
          icon: 'list',
          url: 'vehiculo_ingreso'
        },
        {
          id: 'reportei2',
          title: 'Agencias',
          translate: 'Agencias',
          type: 'item',
          icon: 'list',
          url: 'agencia_ingreso'
        },
        {
          id: 'reportei3',
          title: 'Ciudades',
          translate: 'Ciudades',
          type: 'item',
          icon: 'list',
          url: 'ciudad_ingreso'
        }
      ]
    },
    {
      id: 'mantenimiento',
      title: 'Mantenimiento',
      translate: 'MENU.MANTENIMIENTO',
      type: 'collapsible',
      icon: 'settings',
      children: [
        {
          id: 'ica',
          title: 'Retención ICA',
          translate: 'MENU.RETENCION',
          type: 'item',
          icon: 'list',
          url: 'retencion'
        },
        {
          id: 'usuario',
          title: 'Usuarios',
          translate: 'MENU.USUARIO',
          type: 'item',
          icon: 'list',
          url: 'usuario'
        },
        {
          id: 'recargos',
          title: 'Recargos',
          translate: 'Recargos',
          type: 'item',
          icon: 'list',
          url: 'recargos'
        }
      ]
    }
  ];
}else if(user.rol=='Operador'){
  confi_menu = [
    {
      id: 'home',
      title: 'Inicio',
      translate: 'MENU.HOME',
      type: 'item',
      icon: 'grid',
      url: 'home'
    },
    {
      id: 'informe_diario',
      title: 'Informe Diario',
      translate: 'Informe Diario',
      type: 'collapsible',
      icon: 'clipboard',
      children: [
        {
          id: 'reporte1',
          title: 'Consultar',
          translate: 'Consultar',
          type: 'item',
          icon: 'list',
          url: 'informe_diario'
        }
      ]
    },
    {
      id: 'anticipo',
      title: 'Anticipos',
      translate: 'Anticipos',
      type: 'collapsible',
      icon: 'align-left',
      children: [
        {
          id: 'reporte1',
          title: 'Consultas',
          translate: 'Consultas',
          type: 'item',
          icon: 'list',
          url: 'anticipo'
        }
      ]
    },
    {
      id: 'egresos',
      title: 'Egresos',
      translate: 'Egresos',
      type: 'collapsible',
      icon: 'trending-down',
      children: [
        {
          id: 'reporte2',
          title: 'Consultas',
          translate: 'Consultas',
          type: 'item',
          icon: 'list',
          url: 'egresos'
        }
      ]
    },
    {
      id: 'tiquetes',
      title: 'Tiquetes',
      translate: 'Tiquetes',
      type: 'collapsible',
      icon: 'bookmark',
      children: [
        {
          id: 'reporte3',
          title: 'Consultas',
          translate: 'Consultas',
          type: 'item',
          icon: 'list',
          url: 'tiquetes'
        }
      ]
    }
  ];
}else if(user.rol=='Contabilidad'){
  confi_menu = [
    {
      id: 'home',
      title: 'Inicio',
      translate: 'MENU.HOME',
      type: 'item',
      icon: 'grid',
      url: 'home'
    },
    {
      id: 'anticipo',
      title: 'Anticipos',
      translate: 'Anticipos',
      type: 'collapsible',
      icon: 'align-left',
      children: [
        {
          id: 'reporte1',
          title: 'Consultas',
          translate: 'Consultas',
          type: 'item',
          icon: 'list',
          url: 'anticipo'
        }
      ]
    },
    {
      id: 'egresos',
      title: 'Egresos',
      translate: 'Egresos',
      type: 'collapsible',
      icon: 'trending-down',
      children: [
        {
          id: 'reporte2',
          title: 'Consultas',
          translate: 'Consultas',
          type: 'item',
          icon: 'list',
          url: 'egresos'
        }
      ]
    },
    {
      id: 'ventas',
      title: 'Ventas',
      translate: 'Ventas',
      type: 'collapsible',
      icon: 'trending-up',
      children: [
        {
          id: 'reporte1',
          title: 'Reporte',
          translate: 'Reporte',
          type: 'item',
          icon: 'list',
          url: 'reporte_ventas'
        }
      ]
    },
    {
      id: 'producidos',
      title: 'Producido',
      translate: 'Producido',
      type: 'collapsible',
      icon: 'divide-circle',
      children: [
        {
          id: 'reporte1',
          title: 'Reporte',
          translate: 'Reporte',
          type: 'item',
          icon: 'list',
          url: 'producidos'
        }
      ]
    },
    {
      id: 'anual',
      title: 'Anual',
      translate: 'Anual',
      type: 'collapsible',
      icon: 'calendar',
      children: [
        {
          id: 'reporte2',
          title: 'Reporte',
          translate: 'Reporte',
          type: 'item',
          icon: 'list',
          url: 'reporte_fin_anio'
        }
      ]
    },
    {
      id: 'mensual',
      title: 'Diario Mensual',
      translate: 'Diario Mensual',
      type: 'collapsible',
      icon: 'layout',
      children: [
        {
          id: 'reporte_mensual',
          title: 'Reporte',
          translate: 'Reporte',
          type: 'item',
          icon: 'list',
          url: 'reporte_mensual'
        }
      ]
    },
    {
      id: 'agencia',
      title: 'Agencia',
      translate: 'Agencia',
      type: 'collapsible',
      icon: 'home',
      children: [
        {
          id: 'reporte3',
          title: 'Reporte',
          translate: 'Reporte',
          type: 'item',
          icon: 'list',
          url: 'reporte_agencia'
        }
      ]
    },
    {
      id: 'vehiculo',
      title: 'Buses',
      translate: 'Buses',
      type: 'collapsible',
      icon: 'truck',
      children: [
        {
          id: 'reporte4',
          title: 'Reporte',
          translate: 'Reporte',
          type: 'item',
          icon: 'list',
          url: 'reporte_vehiculo'
        },
        {
          id: 'reporte5',
          title: 'Reporte Detallado',
          translate: 'Reporte Detallado',
          type: 'item',
          icon: 'list',
          url: 'reporte_vehiculo_detallado'
        }
      ]
    }
  ];
}else if(user.rol=='Asociado'){
  confi_menu = [
    {
      id: 'home',
      title: 'Inicio',
      translate: 'MENU.HOME',
      type: 'item',
      icon: 'grid',
      url: 'home'
    },
    {
      id: 'vehiculo',
      title: 'Buses',
      translate: 'Buses',
      type: 'collapsible',
      icon: 'truck',
      children: [
        {
          id: 'reporte4',
          title: 'Reporte',
          translate: 'Reporte',
          type: 'item',
          icon: 'list',
          url: 'reporte_vehiculo'
        },
        {
          id: 'reporte5',
          title: 'Reporte Detallado',
          translate: 'Reporte Detallado',
          type: 'item',
          icon: 'list',
          url: 'reporte_vehiculo_detallado'
        }
      ]
    },
    {
      id: 'documento',
      title: 'Documentos',
      translate: 'Documentos',
      type: 'collapsible',
      icon: 'hard-drive',
      children: [
        {
          id: 'documento1',
          title: 'Lista',
          translate: 'Lista',
          type: 'item',
          icon: 'list',
          url: 'documento'
        }
      ]
    }
  ];
}else if(user.rol=='Gerencia'){
  confi_menu = [
    {
      id: 'home',
      title: 'Inicio',
      translate: 'MENU.HOME',
      type: 'item',
      icon: 'grid',
      url: 'home'
    },
    {
      id: 'ingresos',
      title: 'Ingresos',
      translate: 'Ingresos',
      type: 'collapsible',
      icon: 'dollar-sign',
      children: [
        {
          id: 'reportei1',
          title: 'Buses',
          translate: 'Buses',
          type: 'item',
          icon: 'list',
          url: 'vehiculo_ingreso'
        },
        {
          id: 'reportei2',
          title: 'Agencias',
          translate: 'Agencias',
          type: 'item',
          icon: 'list',
          url: 'agencia_ingreso'
        },
        {
          id: 'reportei3',
          title: 'Ciudades',
          translate: 'Ciudades',
          type: 'item',
          icon: 'list',
          url: 'ciudad_ingreso'
        }
      ]
    }
  ];
}
export const menu: CoreMenu[] = confi_menu
