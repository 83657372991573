<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="bootstrap-select">
      <div class="row">
        

        <div class="col-xl-12 col-md-12 col-12">
          <div class="card ">
            <div class="card-header">
              <h4 class="card-title" >Recargos</h4>
            </div>
            <div class="card-body">

              <form class="auth-login-form mt-2" [formGroup]="loginForm">
                <div class="row">
                  <div class="col-sm-6 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-seguro">Seguro</label>
                      <input
                        type="number"
                        min="0"
                        formControlName="seguro"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.seguro.errors }"
                        placeholder=""
                        aria-describedby="form-seguro"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.seguro.errors" class="invalid-feedback">
                        <div *ngIf="f.seguro.errors.required">Seguro obligatorio</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-cuota_admin_b">Cuota admin(Buses)(%)</label>
                      <input
                        type="number"
                        min="0" 
                        formControlName="cuota_admin_b"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.cuota_admin_b.errors }"
                        placeholder=""
                        aria-describedby="form-cuota_admin_b"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.cuota_admin_b.errors" class="invalid-feedback">
                        <div *ngIf="f.cuota_admin_b.errors.required">Cuota admin(Buses) obligatoria</div>
                        <div *ngIf="f.cuota_admin_b.errors.max">No puedes ingresar un monto mayor a 100</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-cuota_admin_f">Cuota admin(Furgones)(%)</label>
                      <input
                        type="number"
                        min="0" 
                        formControlName="cuota_admin_f"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.cuota_admin_f.errors }"
                        placeholder=""
                        aria-describedby="form-cuota_admin_f"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.cuota_admin_f.errors" class="invalid-feedback">
                        <div *ngIf="f.cuota_admin_f.errors.required">Cuota admin(Furgones) obligatoria</div>
                        <div *ngIf="f.cuota_admin_f.errors.max">No puedes ingresar un monto mayor a 100</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-cuota_admin">Cuota admin(Busetas)(SMMLV)</label>
                      <input
                        type="number"
                        min="0" 
                        formControlName="cuota_admin"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.cuota_admin.errors }"
                        placeholder=""
                        aria-describedby="form-cuota_admin"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.cuota_admin.errors" class="invalid-feedback">
                        <div *ngIf="f.cuota_admin.errors.required">Cuota admin obligatoria</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-cuota_admin_no_asociado">Cuota admin(Busetas) no asociados(% al SMMLV)</label>
                      <input
                        type="number"
                        min="0" max="100"
                        formControlName="cuota_admin_no_asociado"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.cuota_admin_no_asociado.errors }"
                        placeholder=""
                        aria-describedby="form-cuota_admin_no_asociado"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.cuota_admin_no_asociado.errors" class="invalid-feedback">
                        <div *ngIf="f.cuota_admin_no_asociado.errors.required">Cuota admin no asociados obligatoria</div>
                        <div *ngIf="f.cuota_admin_no_asociado.errors.max">No puedes ingresar un monto mayor a 100</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-capitalizacion">Capitalizacion(%)</label>
                      <input
                        type="number"
                        min="0" max="100"
                        formControlName="capitalizacion"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.capitalizacion.errors }"
                        placeholder=""
                        aria-describedby="form-capitalizacion"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.capitalizacion.errors" class="invalid-feedback">
                        <div *ngIf="f.capitalizacion.errors.required">Capitalizacion oligatoria</div>
                        <div *ngIf="f.capitalizacion.errors.max">No puedes ingresar un monto mayor a 100</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label class="form-label" for="form-deposito_deudas">Deposito Deudas(%)</label>
                      <input
                        type="number"
                        min="0" max="100"
                        formControlName="deposito_deudas"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.deposito_deudas.errors }"
                        placeholder=""
                        aria-describedby="form-deposito_deudas"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.deposito_deudas.errors" class="invalid-feedback">
                        <div *ngIf="f.deposito_deudas.errors.required">Deposito Deudas oligatoria</div>
                        <div *ngIf="f.deposito_deudas.errors.max">No puedes ingresar un monto mayor a 100</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label class="form-label" for="form-fondo_reposicion">Fondo reposición(%)</label>
                      <input
                        type="number"
                        min="0" max="100"
                        formControlName="fondo_reposicion"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.fondo_reposicion.errors }"
                        placeholder=""
                        aria-describedby="form-fondo_reposicion"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.fondo_reposicion.errors" class="invalid-feedback">
                        <div *ngIf="f.fondo_reposicion.errors.required">Fondo reposición oligatoria</div>
                        <div *ngIf="f.fondo_reposicion.errors.max">No puedes ingresar un monto mayor a 100</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label class="form-label" for="form-contribu_turismo">Contribución turismo(%)</label>
                      <input
                        type="number"
                        min="0" max="100"
                        formControlName="contribu_turismo"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.contribu_turismo.errors }"
                        placeholder=""
                        aria-describedby="form-contribu_turismo"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.contribu_turismo.errors" class="invalid-feedback">
                        <div *ngIf="f.contribu_turismo.errors.required">Contribución turismo oligatoria</div>
                        <div *ngIf="f.contribu_turismo.errors.max">No puedes ingresar un monto mayor a 100</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label class="form-label" for="form-ahorro">Ahorro(%)</label>
                      <input
                        type="number"
                        min="0" max="100"
                        formControlName="ahorro"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.ahorro.errors }"
                        placeholder=""
                        aria-describedby="form-ahorro"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.ahorro.errors" class="invalid-feedback">
                        <div *ngIf="f.ahorro.errors.required">Ahorro oligatorio</div>
                        <div *ngIf="f.ahorro.errors.max">No puedes ingresar un monto mayor a 100</div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-sm-6 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-gastos">Gastos publicitarios</label>
                      <input
                        type="number"
                        min="0" 
                        formControlName="gastos"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.gastos.errors }"
                        placeholder=""
                        aria-describedby="form-gastos"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.gastos.errors" class="invalid-feedback">
                        <div *ngIf="f.gastos.errors.required">Gastos publicitarios obligatoria</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-12">
                    <br>
                    <button [disabled]="loading" class="btn btn-info btn-block" tabindex="4" rippleEffect (click)="edit()">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Editar 
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
