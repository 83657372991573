<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="bootstrap-select">
      <div class="row">
        <div class="col-12">
          
            <div class="row">
            <div class="col-sm-2 col-12">
              <label>Ciudad</label>
              <div class="form-group">
                <ng-select (change)="buscaragencia()" [items]="ciudades" name="ciudad" [(ngModel)]="selectedCiudad" bindValue="id" bindLabel="Nombre" [loading]="selectBasicLoading" class="form-control"> </ng-select>
              </div>
              
            </div>
            <div class="col-sm-2 col-12">
              <label>Agencias</label>
              <div class="form-group">
                <ng-select [items]="agencias" name="agencias" [(ngModel)]="selectedAgencia" bindValue="Id" bindLabel="Nombre" [loading]="selectBasicLoading2" class="form-control"> </ng-select>
              </div>
              
            </div>
            <div class="col-sm-2 col-12">
              <label>Propietario</label>
              <div class="form-group">
                <ng-select (change)="buscarbuses()" [items]="propietario" name="propietario" [(ngModel)]="selectedPropietario" bindValue="SocioID" bindLabel="Nombre" [loading]="selectBasicLoading3" class="form-control"> </ng-select>
              </div>
              
            </div>
            <div class="col-sm-2 col-12">
              <label>Buses</label>
              <div class="form-group">
                <ng-select [items]="coches" name="coches" [(ngModel)]="selectedCoche" bindValue="id" bindLabel="Nombre" [loading]="selectBasicLoading4" class="form-control"> </ng-select>
              </div>
              
            </div>
            <div class="col-sm-2 col-12">
              <label>Año</label>
              <div class="form-group">
                <ng-select [items]="anios" name="anios" [(ngModel)]="selectedAnio" bindValue="valor" bindLabel="valor" class="form-control"> </ng-select>
              </div>
            </div>
            <div class="col-sm-2 col-12 text-center">
              <button (click)="buscar()" class="btn btn-primary mt-2" rippleEffect>{{txt_btn}}</button>
            </div>
          </div>
        
          <div class="col-sm-12 col-12 text-right" *ngIf="btnDescargas">
            <hr>
              <button (click)="exportexcel()" class="btn btn-success " rippleEffect>Descargar por Excel</button>
              <button (click)="openPDF()" class="btn btn-success ml-1" rippleEffect>Descargar por PDF</button>
              <button (click)="exporter.exportTable('txt', {fileName:'Excel Reporte Anual', delimiter:','})" class="btn btn-success ml-1" rippleEffect>Descargar por TXT</button>
            <hr>
              
            </div>
          </div>
          <div class="col-12 table-responsive">
            <table mat-table matTableExporter  [dataSource]="dataSource" class="table" id="excel-table" #exporter="matTableExporter">

              <ng-container matColumnDef="Nombre_C">
                <th mat-header-cell *matHeaderCellDef> Ciudad. </th>
                <td mat-cell *matCellDef="let element"> {{element.Nombre_C}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <ng-container matColumnDef="Nombre_A">
                <th mat-header-cell *matHeaderCellDef> Agencia </th>
                <td mat-cell *matCellDef="let element"> {{element.Nombre_A}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <ng-container matColumnDef="Propietario">
                <th mat-header-cell *matHeaderCellDef> Propietario </th>
                <td mat-cell *matCellDef="let element">{{element.Documento_So}} - {{element.Nombre_So}} {{element.Apellido_So}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <ng-container matColumnDef="Vehiculo">
                <th mat-header-cell *matHeaderCellDef> Bus </th>
                <td mat-cell *matCellDef="let element"> {{element.Vehiculo}} </td>
                <td mat-footer-cell *matFooterCellDef> <b>Total</b> </td>
              </ng-container>

              <ng-container matColumnDef="producido">
                <th mat-header-cell *matHeaderCellDef> Producido </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.producido)}} </td>
                <td mat-footer-cell *matFooterCellDef> <b>{{formato(tot_pro)}}</b> </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </table>
          </div>
        
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
