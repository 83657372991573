<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="bootstrap-select">
      <div class="row">
        <div class="col-xl-9 col-md-9 col-12" *ngIf="!divform"></div>
        <div class="col-xl-3 col-md-3 col-12 text-right" *ngIf="!divform">
          <button class="btn btn-primary btn-block" tabindex="4" rippleEffect (click)="divform=true">Agregar nueva Retención</button>
        </div>
        <div class="col-xl-12 col-md-12 col-12" *ngIf="!divform">
          <div class="card ">
            <div class="card-body">
              <app-tablelist2 [add]="addForm" [update]="updateForm"  [delete]="deleteForm" (EdititemEvent)="ChooseItem($event)"></app-tablelist2>
            </div>
          </div>
        </div>

        <div class="col-xl-12 col-md-12 col-12" *ngIf="divform">
          <div class="card ">
            <div class="card-header">
              <h4 class="card-title" *ngIf="actions">Agregar Nueva Retención</h4>
              <h4 class="card-title" *ngIf="addAction">Editar Retención</h4>
            </div>
            <div class="card-body">

              <form class="auth-login-form mt-2" [formGroup]="loginForm">
                <div class="row">
                  <div class="col-sm-6 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-ciudad">Ciudad</label>
                      <input
                        type="text"
                        formControlName="ciudad"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.ciudad.errors }"
                        placeholder=""
                        aria-describedby="form-ciudad"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.ciudad.errors" class="invalid-feedback">
                        <div *ngIf="f.ciudad.errors.required">Ciudad obligatoria</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-tarifa">Tarifa(%)</label>
                      <input
                        type="number"
                        min="0" max="100"
                        formControlName="tarifa"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.tarifa.errors }"
                        placeholder=""
                        aria-describedby="form-tarifa"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.tarifa.errors" class="invalid-feedback">
                        <div *ngIf="f.tarifa.errors.required">Tarifa obligatoria</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2 col-12" *ngIf="!addAction">
                    <br>
                    <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="4" rippleEffect (click)="add()">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Agregar 
                    </button>
                  </div>
                  <div class="col-sm-2 col-12" *ngIf="addAction">
                    <br>
                    <button [disabled]="loading" class="btn btn-info btn-block" tabindex="4" rippleEffect (click)="edit()">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Editar 
                    </button>
                  </div>
                  <div class="col-sm-2 col-12" *ngIf="addAction">
                    <br>
                    <button [disabled]="loading" class="btn btn-danger btn-block" tabindex="4" rippleEffect (click)="delete()">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Eliminar 
                    </button>
                  </div>
                  <div class="col-sm-2 col-12">
                    <br>
                    <button [disabled]="loading" class="btn btn-secondary btn-block" tabindex="4" rippleEffect (click)="resetAll()">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Cancelar 
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
