<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="bootstrap-select">
      <div class="row">
        <div class="col-12">
          
            <div class="row">
            <div class="col-sm-3 col-12">
              <label>Cédula o Numero de Tiquete</label>
              <div class="form-group">
                <input type="text" [(ngModel)]="selectedbuscar" class="form-control"/>
              </div>
            </div>
            <div class="col-sm-2 col-12">
              <label>Agencias</label>
              <div class="form-group">
                <ng-select [items]="agencias" name="agencias" [(ngModel)]="selectedAgencia" bindValue="Id" bindLabel="Nombre" [loading]="selectBasicLoading" class="form-control"> </ng-select>
              </div>
            </div>
            <div class="col-sm-2 col-12" >
              <label>Fecha</label>
              <div class="form-group">
                <input type="date" [(ngModel)]="selectedFecha" class="form-control"/>
              </div>
            </div>
            <div class="col-sm-2 col-12 text-center">
              <button (click)="buscar()" class="btn btn-primary mt-2" rippleEffect>{{txt_btn}}</button>
            </div>
          </div>
        
          <div class="col-sm-12 col-12 text-right" *ngIf="btnDescargas">
            <hr>
              <button (click)="exportexcel()" class="btn btn-success " rippleEffect>Descargar por Excel</button>
              <button (click)="openPDF()" class="btn btn-success ml-1" rippleEffect>Descargar por PDF</button>
              <button (click)="exporter.exportTable('txt', {fileName:'Excel Reporte de tiquetes', delimiter:','})" class="btn btn-success ml-1" rippleEffect>Descargar por TXT</button>
            <hr>
              
            </div>
          </div>
          <div class="col-12 table-responsive">
            <table mat-table matTableExporter  [dataSource]="dataSource" class="table" id="excel-table" #exporter="matTableExporter" >

              <ng-container matColumnDef="Numero">
                <th mat-header-cell *matHeaderCellDef> Numero Tiquete </th>
                <td mat-cell *matCellDef="let element"> {{element.Numero}} </td>
              </ng-container>

              <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef> Fecha </th>
                <td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
              </ng-container>

              <ng-container matColumnDef="pasajero">
                <th mat-header-cell *matHeaderCellDef> Pasajero </th>
                <td mat-cell *matCellDef="let element"> {{element.tipo_doc}}-{{element.Documento_P}} {{element.Nombre_P}} {{element.Apellido_P}}</td>
              </ng-container>

              <ng-container matColumnDef="Nombre_A">
                <th mat-header-cell *matHeaderCellDef> Agencia </th>
                <td mat-cell *matCellDef="let element"> {{element.Nombre_A}} </td>
              </ng-container>

              <ng-container matColumnDef="origen">
                <th mat-header-cell *matHeaderCellDef> Origen </th>
                <td mat-cell *matCellDef="let element"> {{element.origen}} </td>
              </ng-container>

              <ng-container matColumnDef="destino">
                <th mat-header-cell *matHeaderCellDef> Destino </th>
                <td mat-cell *matCellDef="let element"> {{element.destino}} </td>
              </ng-container>

              <ng-container matColumnDef="bus">
                <th mat-header-cell *matHeaderCellDef> Bus </th>
                <td mat-cell *matCellDef="let element"> {{element.bus}} </td>
              </ng-container>

              <ng-container matColumnDef="viajo">
                <th mat-header-cell *matHeaderCellDef> ¿viajo? </th>
                <td mat-cell *matCellDef="let element"> {{txtviajo(element)}} </td>
              </ng-container>
              
              <ng-container matColumnDef="importefinal">
                <th mat-header-cell *matHeaderCellDef> Importe </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.importefinal)}} </td>
              </ng-container> 

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
           
          </div>
        
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
