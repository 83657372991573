import { Component, OnInit } from '@angular/core'
import { locale as en } from './i18n/en'
import { locale as fr } from './i18n/fr'
import { locale as de } from './i18n/de'
import { locale as pt } from './i18n/pt'

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { RetencionService } from "app/auth/service/retencion.service";
import  Swal  from 'sweetalert2'; 

import { CoreTranslationService } from '@core/services/translation.service'

@Component({
  selector: 'app-retencion',
  templateUrl: './retencion.component.html',
  styleUrls: ['./retencion.component.scss']
})
export class RetencionComponent implements OnInit {
  public contentHeader: object;
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';

  public addForm: number = 0;
  public updateForm: any;
  public delForm: number = 0;
  public deleteForm: any;

  divform: boolean = false;
  actions: boolean = true;
  addAction: boolean = false;
  idSelected!: number|undefined;
  indexSelected!: number|undefined;
   
  /**
   *
   * @param {CoreTranslationService} _coreTranslationService
   */
  constructor(
    private _coreTranslationService: CoreTranslationService,
    private _formBuilder: FormBuilder, 
    private retencionService: RetencionService) {
    this._coreTranslationService.translate(en, fr, de, pt)
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    
    this.loginForm = this._formBuilder.group({
      ciudad: ['', Validators.required],
      tarifa: ['', Validators.required]
    });

    this.contentHeader = {
      headerTitle: 'Retención ICA',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Inicio',
            isLink: true,
            link: '/'
          },
          {
            name: 'Retención ICA',
            isLink: false
          }
        ]
      }
    }
  }


  get f() {
    return this.loginForm.controls;
  }
  
  ChooseItem(newItem: any) {
    //console.log(newItem);
    this.idSelected = newItem._id;
    this.indexSelected = newItem.index;
    this.loginForm = this._formBuilder.group({
      ciudad: [newItem.ciudad, Validators.required],
      tarifa: [newItem.tarifa, Validators.required]
    });
    this.addAction = true;
    this.divform = true;
    this.actions = false;
  }

  add() {
    this.submitted = true;
    if (this.loginForm.status === "VALID") {
      this.addAction = true;
      this.loading = true;
      let set = {
        ciudad: this.loginForm.controls.ciudad.value,
        tarifa: this.loginForm.controls.tarifa.value
      };
      
      this.retencionService.add(set)
      .subscribe(
        res => {
          //console.log('HTTP response', res)
          Swal.fire('Se agrego correctamente.', '', 'success');
          this.divform = false;
          this.loading = false;
          this.submitted = false;
          this.addAction = false;
          this.addForm++;
          this.loginForm.reset();
        },err => {
          this.loading = false;
          this.submitted = false;
          //this.addAction = false;
          //console.log('HTTP Error', err)
        }
      );
     
    }
  }
  edit () {
    this.submitted = true;
    if (this.loginForm.status === "VALID") {
      this.actions = true;
      this.loading = true;
      let set = {
        id: this.idSelected,
        ciudad: this.loginForm.controls.ciudad.value,
        tarifa: this.loginForm.controls.tarifa.value || '',
        index: this.indexSelected
      };
      
      
      this.retencionService.edit(set)
      .subscribe(
        res => {
          //console.log('HTTP response', res)
          Swal.fire('Se edito correctamente.', '', 'success');
          this.divform = false;
          this.loading = false;
          this.submitted = false;
          this.addAction = false;
          this.idSelected = undefined;
          this.loginForm.reset();
          this.updateForm = set;
        },err => {
          this.loading = false;
          this.submitted = false;
          //this.addAction = false;
          //console.log('HTTP Error', err)
        });
    }
  }

  delete() {
    if (this.idSelected) {
      this.actions = true;
      this.loading = true;
      this.loginForm.reset();
      this.retencionService.delete(this.idSelected)
      .subscribe((trae: any) => {
        Swal.fire('Se elimino correctamente.', '', 'success');
        this.deleteForm = {index: this.indexSelected, delForm: this.delForm++};
        this.idSelected = undefined;
        this.indexSelected = undefined;
        this.loading = false;
        this.addAction = false;
        this.divform = false;
      });
    }
  }
  resetAll() {
    this.actions = true;
    this.divform = false;
    this.addAction = false;
    //this.loginForm.reset();
    this.idSelected = undefined;
    this.indexSelected = undefined;
  }
}
