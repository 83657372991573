import { Component, OnInit } from '@angular/core'
import { locale as en } from './i18n/en'
import { locale as fr } from './i18n/fr'
import { locale as de } from './i18n/de'
import { locale as pt } from './i18n/pt'

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { UsuarioService } from "app/auth/service/usuario.service";
import { ReportesService } from "app/auth/service/reportes.service";
import  Swal  from 'sweetalert2'; 
import { Router } from '@angular/router';
import { environment } from 'environments/environment'; 

import { CoreTranslationService } from '@core/services/translation.service'

export interface Tipo {
  Nombre: string;
}

export interface Socio {
  SocioID: string;
  Nombre: string;
}

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  public contentHeader: object;
  public loginForm: FormGroup;
  public loading = false;
  public loading2 = false;
  public submitted = false;
  public submitted2 = false;
  public returnUrl: string;
  public error = '';

  public addForm: number = 0;
  public updateForm: any;
  public delForm: number = 0;
  public deleteForm: any;

  mostrardocumento: boolean = false;
  idSelected!: number|undefined;
  tipo_nivel: Tipo[] = [{Nombre: 'Administrador'},
    {Nombre: 'Operador'},
    {Nombre: 'Contabilidad'},
    {Nombre: 'Asociado'},
    {Nombre: 'Gerencia'}];

  socios: Socio[] = [];
  selectedrol: string; 
  nombre_usuario: string;

  file: any = [];
  titulo: string;
  archivo: string;
  archivos: any = [];
  /**
   *
   * @param {CoreTranslationService} _coreTranslationService
   */
  constructor(
    private _coreTranslationService: CoreTranslationService,
    private _formBuilder: FormBuilder, 
    private usuarioService: UsuarioService, 
    private _router: Router,
    private reportesService: ReportesService) {
    this._coreTranslationService.translate(en, fr, de, pt)
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}');
    //console.log(user);
    this.idSelected = user._id;
    this.mostrardocumento = true;
    this.loginForm = this._formBuilder.group({
      email: [user.email, [Validators.required, Validators.email]],
      password: ['', Validators.required],
      usuario: [user.usuario, Validators.required],
      //rol: [user.rol, Validators.required],
      socio: [user.id_socio]
    });
    this.nombre_usuario = user.usuario;
    this.selectedrol = user.rol;
    this.titulo = '';
    this.file = [];
    this.archivo = '';
    
    this.reportesService.getSocios()
    .subscribe((trae: any) => {
      //console.log(trae);
      if(trae.data[0]){
        this.socios = trae.data[0];
      }
    });

    this.contentHeader = {
      headerTitle: 'Perfil',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Inicio',
            isLink: true,
            link: '/'
          },
          {
            name: 'Perfil',
            isLink: false
          }
        ]
      }
    }
  }


  get f() {
    return this.loginForm.controls;
  }

  edit () {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}');
    
    this.submitted = true;
    if (this.loginForm.status === "VALID") {
      this.loading = true;
      let set = {
        id: this.idSelected,
        email: this.loginForm.controls.email.value,
        password: this.loginForm.controls.password.value || '',
        usuario: this.loginForm.controls.usuario.value,
        rol: user.rol,
        index: 0,
        id_socio: ''
      };
      if(set.rol=='Asociado')
        set.id_socio = this.loginForm.controls.socio.value;
      //console.log(set);
      this.usuarioService.edit(set)
      .subscribe(
        res => {
          //console.log('HTTP response', res)
          Swal.fire('Se edito correctamente.', '', 'success');
          user.email = this.loginForm.controls.email.value;
          user.usuario = this.loginForm.controls.usuario.value;
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.loading = false;
          this.submitted = false;
          this.mostrardocumento = false;
          setTimeout(() => {
            window.location.href='/perfil';
          }, 1500);
        },err => {
          this.loading = false;
          this.submitted = false;
          
        });
    }
  }

  onFileSelected(event: any) {

    const imagen = event.target.files[0];
    console.log(imagen);
    if (imagen) {
      this.file = imagen;
      /*this.blobFile(imagen).then((res: any) => {
        this.imagenPrevia = res.base;

      })*/
    } else {
      console.log('No cargo nada');

    }
  }

  /*getfile(){
    this.usuarioService.getfile(this.idSelected)
      .subscribe((trae: any) => {
        
        if(trae.data){
          this.archivos = trae.data;
        }
        //console.log(this.archivos);
      });
  }

  addfile() {
    this.submitted2 = true;
    if(this.titulo=='')
      return;
    else if(this.archivo == '')
      return
    
    
      let set = {
        id: this.idSelected,
        file: this.file,
        titulo: this.titulo
      };
      
      //console.log(set);
      
      this.usuarioService.addfile(set)
      .subscribe(
        res => {
          //console.log('HTTP response', res)
          Swal.fire(res.message, '', 'success');
          this.loading2 = false;
          this.submitted2 = false;
          this.titulo = '';
          this.file = [];
          this.archivo = '';
          this.getfile();
        },err => {
          this.loading2 = false;
          
         
        });
 
  }

  deletefile(id) {
    
      
      this.usuarioService.deletefile(id)
      .subscribe((trae: any) => {
        Swal.fire('Se elimino correctamente.', '', 'success');
        this.getfile();
      });
    
  }

  verfile(r) {
    console.log(environment.apiUrl,r.ruta);
    //window.location.href=environment.apiUrl+'files/'+r.ruta;
    window.open(environment.apiUrl+'files/'+r.ruta, '_blank');
  }*/

}
