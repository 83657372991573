<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="bootstrap-select">
      <div class="row">
        <div class="col-12">
          <form class="auth-login-form mt-2" [formGroup]="form">
            <div class="row">
              <div class="col-sm-2 col-12">
                <label>Tipo Filtro</label>
                <div class="form-group">
                  <select formControlName="tipo" class="form-control" (change)="buscar()">
                    <option *ngFor="let tipo of tipo_bus" [value]="tipo.Nombre">{{tipo.Nombre}}</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-2 col-12">
                <label>Fecha Inicial</label>
                <div class="form-group">
                  <input type="date" formControlName="desde" class="form-control"/>
                </div>
              </div>
              <div class="col-sm-2 col-12">
                <label>Fecha Fin</label>
                <div class="form-group">
                  <input type="date" formControlName="hasta" class="form-control"/>
                </div>
              </div>
              <div class="col-sm-2 col-12">
                <label>Tipo Sucursal</label>
                <div class="form-group">
                  <select formControlName="tipoSucursal" class="form-control" >
                    <option value="OPERATIVA">OPERATIVA</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-2 col-12">
                <label>Suculsal</label>
                <div class="form-group">
                  <ng-select [items]="agencias" formControlName="sucursal" bindValue="Id" bindLabel="Nombre" [loading]="selectBasicLoading" class="form-control" (change)="updateSelect($event)"> </ng-select>
                  <!--<select formControlName="sucursal" class="form-control" >
                    
                  </select>-->
                </div>
              </div>
              <div class="col-sm-2 col-12">
                <label>Tipo y Codigo Comprobante</label>
                <div class="form-group">
                  <input type="text" formControlName="comprobante" class="form-control"/>
                </div>
              </div>
              <div class="col-sm-2 col-12">
                <label>Nro Documento Desde</label>
                <div class="form-group">
                  <input type="number" min="1" formControlName="nrDesde" class="form-control"/>
                </div>
              </div>
              <div class="col-sm-2 col-12 text-center">
                <button [disabled]="loading" (click)="generar()" class="btn btn-primary mt-2" rippleEffect>{{txt_btn}}</button>
              </div>
            </div>
          </form>
          </div>
          <div class="col-12 table-responsive">
            <table mat-table matTableExporter  [dataSource]="dataSource" class="table">

              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Doc </th>
                <td mat-cell *matCellDef="let element"> {{element.doc}}</td>
              </ng-container>

              <ng-container matColumnDef="sucursal">
                <th mat-header-cell *matHeaderCellDef> Sucursal </th>
                <td mat-cell *matCellDef="let element"> {{element.sucursal!='0'? element.sucursalTxt:'TODAS LAS SUCURSALES'}} </td>
              </ng-container>

              <ng-container matColumnDef="cod">
                <th mat-header-cell *matHeaderCellDef> Cod.Tipo </th>
                <td mat-cell *matCellDef="let element"> {{element.comprobante}} </td>
              </ng-container>

              <ng-container matColumnDef="desde">
                <th mat-header-cell *matHeaderCellDef> Nro Desde </th>
                <td mat-cell *matCellDef="let element"> {{element.nrDesde}} </td>
              </ng-container>

              <ng-container matColumnDef="fechaDesde">
                <th mat-header-cell *matHeaderCellDef> Fecha Desde </th>
                <td mat-cell *matCellDef="let element"> {{element.desde}} </td>
              </ng-container>

              <ng-container matColumnDef="fechaHasta">
                <th mat-header-cell *matHeaderCellDef> Fecha Hasta </th>
                <td mat-cell *matCellDef="let element"> {{element.hasta}} </td>
              </ng-container>

              <ng-container matColumnDef="totalD">
                <th mat-header-cell *matHeaderCellDef> Total Debito </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.totalD)}} </td>
              </ng-container>

              <ng-container matColumnDef="totalC">
                <th mat-header-cell *matHeaderCellDef> Total Credito </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.totalC)}} </td>
              </ng-container>

              <ng-container matColumnDef="diferencia">
                <th mat-header-cell *matHeaderCellDef> Diferencia </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.totalD-element.totalC)}} </td>
              </ng-container>

              <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef> Fecha Creación </th>
                <td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
              </ng-container>

              <ng-container matColumnDef="usuario">
                <th mat-header-cell *matHeaderCellDef> Realizado por </th>
                <td mat-cell *matCellDef="let element"> {{element.usuario}} </td>
              </ng-container> 

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Ancciones </th>
                <td mat-cell *matCellDef="let element"> 
                  <div class="avatar bg-light-success mr-1" (click)="descargarTxt(element)">
                    <div class="avatar-content">
                      <i data-feather="download" class="avatar-icon"></i>
                    </div>
                  </div>
                  <div class="avatar bg-light-danger" (click)="deleteTxt(element._id)">
                    <div class="avatar-content">
                      <i data-feather="delete" class="avatar-icon"></i>
                    </div>
                  </div>
                </td>
              </ng-container> 

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="12">No hay datos</td>
              </tr>
            </table>
           
          </div>
        
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
