import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment'; 
import  Swal  from 'sweetalert2'; 

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  apiUrl: String;
  constructor(
    private _http: HttpClient,
  ) {
    this.apiUrl = environment.apiUrl+ "/user/";
  }
  get(pagina: string, items: string, buscar: string): Observable<any> {
    return this._http.get<any>(this.apiUrl + 'get/', { params: new HttpParams().set('pagina',pagina).set('items',items).set('buscar',buscar)});
  }
  add(params: Object): Observable<any> {
    return this._http.post<any>(this.apiUrl + 'register', params)
    .pipe(
      map((res: any)=>{
        return res;
      }),
      catchError((err) => this.handlerError(err))
    );
  }
  addfile(params: any): any {
    //console.log(params);
    const formData = new FormData();
    formData.append('id', params.id);
    formData.append('titulo', params.titulo);
    formData.append('file', params.file);
    
    return this._http.post<any>(this.apiUrl + 'documento', formData)
    .pipe(
      map((res: any)=>{
        return res;
      }),
      catchError((err) => this.handlerError(err))
    );
  }
  getfile(id: number): Observable<any> {
    return this._http.get<any>(this.apiUrl + 'documento', { params: new HttpParams().set('id',id)});
  }
  deletefile(id: string): Observable<any> {
    return this._http.delete<any>(this.apiUrl + 'documento/' + id, {});
  }

  edit(params: Object): Observable<any> {
    return this._http.put<any>(this.apiUrl + 'update', params).pipe(
      map((res: any)=>{
        return res;
      }),
      catchError((err) => this.handlerError(err))
    );
  }
  delete(id: Number): Observable<any> {
    return this._http.delete<any>(this.apiUrl + 'delete/' + id, {});
  }

  private handlerError(err): Observable<never> {
    //console.log(err)
    let errorMessage = 'ha ocurrido un error';
    if (err) {
      errorMessage = `${err.error.error}`;
    }
    Swal.fire(errorMessage, '', 'error');
    return throwError(errorMessage);
  }
}
