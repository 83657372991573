import { Component, Input, OnInit, SimpleChange, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UsuarioService } from "app/auth/service/usuario.service";

export interface EmployeeElement {
  id?: number;
  usuario: string;
  email: string;
  password: string;
  rol?: string;
}

const ELEMENT_DATA: EmployeeElement[] = [];

@Component({
  selector: 'app-tablelist',
  templateUrl: './tablelist.component.html',
  styleUrls: ['./tablelist.component.scss']
})
export class TablelistComponent implements OnInit {
  @Output() EdititemEvent = new EventEmitter<any>();
  @Input() add: number|undefined;
  @Input() update: any;
  @Input() delete: any;

  total: number = 0;
  page: string = '0';
  numItems: string = '10';
  buscar: string = "";
  displayedColumns: string[] = ['usuario', 'email', 'rol', "actions"];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  
  constructor(private employeesService: UsuarioService) { }

  ngOnInit(): void {
    this.get();
  }
  ngOnChanges(changes: { [property: string]: SimpleChange }){
    //console.log(changes);
      if (changes.add && changes.add.currentValue) {
        this.get();
      } else if (changes.update && changes.update.currentValue !== undefined) {
        this.dataSource.data[Number(changes.update.currentValue.index)].email = changes.update.currentValue.email;
        this.dataSource.data[Number(changes.update.currentValue.index)].usuario = changes.update.currentValue.usuario;
        this.dataSource.data[Number(changes.update.currentValue.index)].password = changes.update.currentValue.password;
        this.dataSource.data[Number(changes.update.currentValue.index)].rol = changes.update.currentValue.rol || '';
        this.dataSource._updateChangeSubscription();
        this.update = {};
      } else if (changes.delete && changes.delete.currentValue !== undefined) {
        delete this.dataSource.data[Number(changes.delete.currentValue.index)];
        this.dataSource.data.splice(Number(changes.delete.currentValue.index), 1);
        this.dataSource._updateChangeSubscription();
        this.delete = {};
      }
  }
  pageSelect(page: any) {
    this.page = page.pageIndex;
    this.get();
  }
  get() {
    this.employeesService.get(this.page, this.numItems, this.buscar)
      .subscribe((trae: any) => {
       this.total = trae.total || 0;
       this.dataSource.data = trae.data;
       this.dataSource._updateChangeSubscription();
      });
  }

  applyFilter(event: Event) {
    //const filterValue = (event.target as HTMLInputElement).value;
    //this.dataSource.filter = filterValue.trim().toLowerCase();
    this.page='0';
    this.numItems='10';
    setTimeout(() => { this.get(); }, 500);
  }
  addNewItem(value: any, index: number) {
    value.index = index;
    this.EdititemEvent.emit(value);
  }
}
