import { Component, OnInit } from '@angular/core'
import { locale as en } from './i18n/en'
import { locale as fr } from './i18n/fr'
import { locale as de } from './i18n/de'
import { locale as pt } from './i18n/pt'

import { ErrorStateMatcher } from '@angular/material/core';
import { UsuarioService } from "app/auth/service/usuario.service";
import  Swal  from 'sweetalert2'; 
import { Router } from '@angular/router';
import { environment } from 'environments/environment'; 

import { CoreTranslationService } from '@core/services/translation.service'

@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.scss']
})
export class DocumentoComponent implements OnInit {
  public contentHeader: object;
  public error = '';

  mostrardocumento: boolean = false;
  idSelected!: number|undefined;
    
  archivos: any = [];
  /**
   *
   * @param {CoreTranslationService} _coreTranslationService
   */
  constructor(
    private _coreTranslationService: CoreTranslationService,
    private usuarioService: UsuarioService, 
    private _router: Router) {
    this._coreTranslationService.translate(en, fr, de, pt)
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}');
    //console.log(user);
    this.idSelected = user._id;
    if(user.rol=='Asociado'){
      this.getfile();
    }
    

    this.contentHeader = {
      headerTitle: 'Documentos',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Inicio',
            isLink: true,
            link: '/'
          },
          {
            name: 'Documentos',
            isLink: false
          }
        ]
      }
    }
  }


  getfile(){
    this.usuarioService.getfile(this.idSelected)
      .subscribe((trae: any) => {
        
        if(trae.data){
          this.archivos = trae.data;
        }
        //console.log(this.archivos);
      });
  }

  verfile(r) {
    console.log(environment.apiUrl,r.ruta);
    //window.location.href=environment.apiUrl+'files/'+r.ruta;
    window.open(environment.apiUrl+'files/'+r.ruta, '_blank');
  }

}
