import { Role } from './role';

export class User {
  _id: string;
  email: string;
  password?: string;
  usuario: string;
  rol: Role;
  token?: string;
  id_socio?: string;
  afiliado: boolean;
}
