import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import  Swal  from 'sweetalert2'; 

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.rol === Role.Administrador;
  }

  /**
   *  Confirms if user is client
   */
  /*get isClient() {
    return this.currentUser && this.currentUserSubject.value.rol === Role.Client;
  }*/

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/auth/login`, { email, password })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                '¡Haz iniciado sesión correctamente! 🎉',
                '👋 Bienvenido, ' + user.usuario + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(user);
          }

          return user;
        }),
        catchError((err) => this.handlerError(err))
      );
  }

  forgot(params: Object): Observable<any> {
    return this._http.put<any>(`${environment.apiUrl}/auth/reset`, params).pipe(
      map((res: any)=>{
        return res;
      }),
      catchError((err) => this.handlerError(err))
    );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }

  private handlerError(err): Observable<never> {
    //console.log(err)
    let errorMessage = 'ha ocurrido un error';
    if (err) {
      errorMessage = `${err.error.error}`;
    }
    Swal.fire(errorMessage, '', 'error')
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
