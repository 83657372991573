<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="bootstrap-select">
      <div class="row">
        <div class="col-12">
          
            <div class="row">
            <div class="col-sm-3 col-12">
              <label>Agencias</label>
              <div class="form-group">
                <ng-select [items]="agencias" name="agencias" [(ngModel)]="selectedAgencia" bindValue="Id" bindLabel="Nombre" [loading]="selectBasicLoading" class="form-control"> </ng-select>
              </div>
              
            </div>
            <div class="col-sm-3 col-12">
              <label>Fecha</label>
              <div class="form-group">
                <input type="date" [(ngModel)]="selectedDesde" class="form-control"/>
              </div>
            </div>
            <!--<div class="col-sm-2 col-12">
              <label>Saldo inicial</label>
              <div class="form-group">
                <input type="number" min="1" [(ngModel)]="selectedmonto" class="form-control"/>
              </div>
            </div>-->
            <div class="col-sm-1 col-12 text-center">
              <button (click)="buscar()" class="btn btn-primary mt-2" rippleEffect>{{txt_btn}}</button>
            </div>
          </div>
        
          <div class="col-sm-12 col-12 text-right" *ngIf="btnDescargas">
            <hr>
              <button (click)="exportexcel()" class="btn btn-success " rippleEffect>Descargar por Excel</button>
              <button (click)="openPDF()" class="btn btn-success ml-1" rippleEffect>Descargar por PDF</button>
              <button (click)="exporter.exportTable('txt', {fileName:'Excel Reporte Diario', delimiter:','})" class="btn btn-success ml-1" rippleEffect>Descargar por TXT</button>
            <hr>
              
          </div>
          </div>
          <div class="col-12 table-responsive">
            <table mat-table matTableExporter  [dataSource]="dataSource" class="table" id="excel-table" #exporter="matTableExporter">

              <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef> Fecha </th>
                <td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
              </ng-container>

              <ng-container matColumnDef="Inicial">
                <th mat-header-cell *matHeaderCellDef> Saldo Inicial </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.Inicial)}} </td>
              </ng-container>

              <ng-container matColumnDef="Tiquetes">
                <th mat-header-cell *matHeaderCellDef> Tiquetes</th>
                <td mat-cell *matCellDef="let element"> {{formato(element.Tiquetes)}} </td>
              </ng-container>

              <ng-container matColumnDef="Anulados">
                <th mat-header-cell *matHeaderCellDef> Tiquetes Anulados </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.Anulados)}} </td>
              </ng-container>

              <ng-container matColumnDef="Arriendo">
                <th mat-header-cell *matHeaderCellDef> Arriendo </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.Arriendo)}} </td>
              </ng-container>

              <ng-container matColumnDef="Egresos">
                <th mat-header-cell *matHeaderCellDef> Gastos varios </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.Egresos)}} </td>
              </ng-container>

              <ng-container matColumnDef="Comisiones">
                <th mat-header-cell *matHeaderCellDef> Comisiones </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.Comisiones)}} </td>
              </ng-container>

              <ng-container matColumnDef="otros_ing">
                <th mat-header-cell *matHeaderCellDef> Otros Ingresos </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.otros_ing)}} </td>
              </ng-container>

              <ng-container matColumnDef="Boucher">
                <th mat-header-cell *matHeaderCellDef> Boucher </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.Boucher)}} </td>
              </ng-container>

              <ng-container matColumnDef="Anticipos">
                <th mat-header-cell *matHeaderCellDef> Anticipos </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.Anticipos)}} </td>
              </ng-container>

              <ng-container matColumnDef="Consignaciones">
                <th mat-header-cell *matHeaderCellDef> Consigna<br>ciones </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.Consignaciones)}} </td>
              </ng-container>

              <ng-container matColumnDef="otros_egre">
                <th mat-header-cell *matHeaderCellDef> Otros Egresos </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.otros_egre)}} </td>
              </ng-container>

              <ng-container matColumnDef="Cierre">
                <th mat-header-cell *matHeaderCellDef> Cierre Global </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.Cierre)}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
