import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { VentasComponent } from './ventas.component';
import { FinanioComponent } from './finanio.component';
import { AgenciaComponent } from './agencia.component';
import { VehiculoComponent } from './vehiculo.component';
import { VehiculoDetalladoComponent } from './vehiculo.detallado.component';
import { RetencionComponent } from './retencion.component';
import { UsuarioComponent } from './usuario.component';
import { SociosComponent } from './socios.component';
import { RecargosComponent } from './recargos.component';
import { MensualComponent } from './mensual.component';
import { PerfilComponent } from './perfil.component';
import { ProducidosComponent } from './producidos.component';
import { AnticipoComponent } from './operador/anticipo.component';
import { EgresosComponent } from './operador/egresos.component';
import { TiquetesComponent } from './operador/tiquetes.component';
import { VehiculoIngComponent } from './gerencia/vehiculo_ing.component';
import { AgenciaIngComponent } from './gerencia/agencia_ing.component';
import { CiudadIngComponent } from './gerencia/ciudad_ing.component';
import { DocumentoComponent } from './documento.component';
import { ReportediarioComponent } from './reportediario.component';
import { TxtPlanoComponent } from './txtPlano/txtPlano.component';
import { TablelistComponent } from './tablelist/tablelist.component';
import { TablelistComponent2 } from './tablelistica/tablelist.component';
import { TablelistComponent3 } from './tablelistsocio/tablelist.component';
import { TablelistComponent4 } from './tablelistconsignacion/tablelist.component';
import { HomeComponent } from './home.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableExporterModule } from 'mat-table-exporter';
import { NgSelectModule } from '@ng-select/ng-select';

const routes = [
  {
    path: 'reporte_ventas',
    component: VentasComponent,
    data: { animation: 'ventas' }
  },
  {
    path: 'reporte_fin_anio',
    component: FinanioComponent,
    data: { animation: 'finanio' }
  },
  {
    path: 'reporte_agencia',
    component: AgenciaComponent,
    data: { animation: 'agencia' }
  },
  {
    path: 'reporte_vehiculo',
    component: VehiculoComponent,
    data: { animation: 'vehiculo' }
  },
  {
    path: 'reporte_vehiculo_detallado',
    component: VehiculoDetalladoComponent,
    data: { animation: 'vehiculo' }
  },
  {
    path: 'retencion',
    component: RetencionComponent,
    data: { animation: 'retencion' }
  },
  {
    path: 'usuario',
    component: UsuarioComponent,
    data: { animation: 'usuario' }
  },
  {
    path: 'vehiculos',
    component: SociosComponent,
    data: { animation: 'vehiculos' }
  },
  {
    path: 'recargos',
    component: RecargosComponent,
    data: { animation: 'recargos' }
  },
  {
    path: 'reporte_mensual',
    component: MensualComponent,
    data: { animation: 'mensual' }
  },
  {
    path: 'home',
    component: HomeComponent,
    data: { animation: 'home' }
  },
  {
    path: 'perfil',
    component: PerfilComponent,
    data: { animation: 'perfil' }
  },
  {
    path: 'documento',
    component: DocumentoComponent,
    data: { animation: 'documento' }
  },
  {
    path: 'anticipo',
    component: AnticipoComponent,
    data: { animation: 'anticipo' }
  },
  {
    path: 'egresos',
    component: EgresosComponent,
    data: { animation: 'egresos' }
  },
  {
    path: 'tiquetes',
    component: TiquetesComponent,
    data: { animation: 'tiquetes' }
  },
  {
    path: 'informe_diario',
    component: ReportediarioComponent,
    data: { animation: 'informe_diario' }
  },
  {
    path: 'vehiculo_ingreso',
    component: VehiculoIngComponent,
    data: { animation: 'vehiculo_ingreso' }
  },
  {
    path: 'agencia_ingreso',
    component: AgenciaIngComponent,
    data: { animation: 'agencia_ingreso' }
  },
  {
    path: 'ciudad_ingreso',
    component: CiudadIngComponent,
    data: { animation: 'ciudad_ingreso' }
  },
  {
    path: 'producidos',
    component: ProducidosComponent,
    data: { animation: 'producidos' }
  },
  {
    path: 'archivoPlano',
    component: TxtPlanoComponent,
    data: { animation: 'producidos' }
  }
];

@NgModule({
  declarations: [VentasComponent, HomeComponent, FinanioComponent, AgenciaComponent, VehiculoComponent, RetencionComponent, TablelistComponent, TablelistComponent2, TablelistComponent3, TablelistComponent4, UsuarioComponent, SociosComponent, RecargosComponent, MensualComponent, PerfilComponent, DocumentoComponent, AnticipoComponent, EgresosComponent, TiquetesComponent, ReportediarioComponent, VehiculoIngComponent, AgenciaIngComponent, CiudadIngComponent, ProducidosComponent, VehiculoDetalladoComponent, TxtPlanoComponent],
  imports: [RouterModule.forChild(routes), ContentHeaderModule, TranslateModule, CoreCommonModule,MatTableModule,MatTableExporterModule,NgSelectModule,MatPaginatorModule],
  exports: [VentasComponent, HomeComponent, FinanioComponent, AgenciaComponent, VehiculoComponent, RetencionComponent, UsuarioComponent, SociosComponent, RecargosComponent, MensualComponent, PerfilComponent, DocumentoComponent, AnticipoComponent, EgresosComponent, TiquetesComponent, ReportediarioComponent, VehiculoIngComponent, AgenciaIngComponent, CiudadIngComponent, ProducidosComponent, VehiculoDetalladoComponent, TxtPlanoComponent]
})
export class SampleModule {}
