import { Component, OnInit } from '@angular/core'
import { locale as en } from './i18n/en'
import { locale as fr } from './i18n/fr'
import { locale as de } from './i18n/de'
import { locale as pt } from './i18n/pt'

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { VehiculosService } from "app/auth/service/vehiculos.service";
import { ReportesService } from "app/auth/service/reportes.service";
import  Swal  from 'sweetalert2'; 

import { CoreTranslationService } from '@core/services/translation.service'

export interface Socio {
  SocioID: string;
  Nombre: string;
}
export interface Tipo {
  Nombre: string;
}

@Component({
  selector: 'app-socios',
  templateUrl: './socios.component.html',
  styleUrls: ['./socios.component.scss']
})
export class SociosComponent implements OnInit {
  public contentHeader: object;
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';

  public addForm: number = 0;
  public updateForm: any;
  public delForm: number = 0;
  public deleteForm: any;

  tipos: Tipo[] = [{Nombre: 'Bus / Buseton'},
    {Nombre: 'Buseta'},
    {Nombre: 'Furgon'}];
  socios: Socio[] = [];
  divform: boolean = false;
  actions: boolean = true;
  addAction: boolean = false;
  idSelected!: number|undefined;
  indexSelected!: number|undefined;
   
  /**
   *
   * @param {CoreTranslationService} _coreTranslationService
   */
  constructor(
    private _coreTranslationService: CoreTranslationService,
    private _formBuilder: FormBuilder, 
    private vehiculosService: VehiculosService,
    private reportesService: ReportesService) {
    this._coreTranslationService.translate(en, fr, de, pt)
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.reportesService.getSocios()
    .subscribe((trae: any) => {
      //console.log(trae);
      if(trae.data[0]){
        this.socios = trae.data[0];
      }
    });
    this.loginForm = this._formBuilder.group({
      nombre: ['', Validators.required],
      matricula: ['', Validators.required],
      butacas: ['', Validators.required],
      carroceria: ['', Validators.required],
      id_socio: ['', Validators.required],
      tipo: ['', Validators.required],
      contable: ['', Validators.required]
    });

    this.contentHeader = {
      headerTitle: 'Buses',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Inicio',
            isLink: true,
            link: '/'
          },
          {
            name: 'Buses',
            isLink: false
          }
        ]
      }
    }
  }


  get f() {
    return this.loginForm.controls;
  }
  
  ChooseItem(newItem: any) {
    //console.log(newItem);
    this.idSelected = newItem._id;
    this.indexSelected = newItem.index;
    this.loginForm = this._formBuilder.group({
      nombre: [newItem.nombre, Validators.required],
      matricula: [newItem.matricula, Validators.required],
      butacas: [newItem.butacas, Validators.required],
      carroceria: [newItem.carroceria, Validators.required],
      id_socio: [newItem.id_socio, Validators.required],
      tipo: [newItem.tipo, Validators.required],
      contable: [newItem.codContable, Validators.required]
    });
    this.addAction = true;
    this.divform = true;
    this.actions = false;
  }

  edit () {
    this.submitted = true;
    if (this.loginForm.status === "VALID") {
      this.actions = true;
      this.loading = true;
      let set = {
        id_: this.idSelected,
        nombre: this.loginForm.controls.nombre.value,
        matricula: this.loginForm.controls.matricula.value,
        butacas: this.loginForm.controls.butacas.value,
        carroceria: this.loginForm.controls.carroceria.value,
        id_socio: this.loginForm.controls.id_socio.value,
        tipo: this.loginForm.controls.tipo.value,
        contable: this.loginForm.controls.contable.value,
        index: this.indexSelected
      };
      
      
      this.vehiculosService.edit(set)
      .subscribe(
        res => {
          //console.log('HTTP response', res)
          Swal.fire('Se edito correctamente.', '', 'success');
          this.divform = false;
          this.loading = false;
          this.submitted = false;
          this.addAction = false;
          this.idSelected = undefined;
          this.loginForm.reset();
          this.updateForm = set;
        },err => {
          this.loading = false;
          this.submitted = false;
          //this.addAction = false;
          //console.log('HTTP Error', err)
        });
    }
  }

  resetAll() {
    this.actions = true;
    this.divform = false;
    this.addAction = false;
    //this.loginForm.reset();
    this.idSelected = undefined;
    this.indexSelected = undefined;
  }
}
