import { Component, OnInit } from '@angular/core'
import { locale as en } from './i18n/en'
import { locale as fr } from './i18n/fr'
import { locale as de } from './i18n/de'
import { locale as pt } from './i18n/pt'

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { UsuarioService } from "app/auth/service/usuario.service";
import { ReportesService } from "app/auth/service/reportes.service";
import  Swal  from 'sweetalert2'; 
import { environment } from 'environments/environment'; 

import { CoreTranslationService } from '@core/services/translation.service'

export interface Tipo {
  Nombre: string;
}

export interface Socio {
  SocioID: string;
  Nombre: string;
}

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {
  public contentHeader: object;
  public loginForm: FormGroup;
  public loading = false;
  public loading2 = false;
  public submitted = false;
  public submitted2 = false;
  public returnUrl: string;
  public error = '';

  public addForm: number = 0;
  public updateForm: any;
  public delForm: number = 0;
  public deleteForm: any;

  divform: boolean = false;
  actions: boolean = true;
  addAction: boolean = false;
  mostrardocumento: boolean = false;
  idSelected!: number|undefined;
  indexSelected!: number|undefined;
  tipo_nivel: Tipo[] = [{Nombre: 'Administrador'},
    {Nombre: 'Operador'},
    {Nombre: 'Contabilidad'},
    {Nombre: 'Asociado'},
    {Nombre: 'Gerencia'}];

  socios: Socio[] = [];
  selectedrol: string; 
  file: any = [];
  titulo: string;
  archivo: string;
  archivos: any = [];
  /**
   *
   * @param {CoreTranslationService} _coreTranslationService
   */
  constructor(
    private _coreTranslationService: CoreTranslationService,
    private _formBuilder: FormBuilder, 
    private usuarioService: UsuarioService, 
    private reportesService: ReportesService) {
    this._coreTranslationService.translate(en, fr, de, pt)
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.reportesService.getSocios()
    .subscribe((trae: any) => {
      //console.log(trae);
      if(trae.data[0]){
        this.socios = trae.data[0];
      }
    });
    
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      usuario: ['', Validators.required],
      rol: ['', Validators.required],
      socio: [''],
      afiliado: [false]
    });

    this.contentHeader = {
      headerTitle: 'Usuarios',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Inicio',
            isLink: true,
            link: '/'
          },
          {
            name: 'Usuarios',
            isLink: false
          }
        ]
      }
    }
  }


  get f() {
    return this.loginForm.controls;
  }
  
  ChooseItem(newItem: any) {
    //console.log(newItem);
    this.idSelected = newItem._id;
    this.mostrardocumento = true;
    this.indexSelected = newItem.index;
    this.loginForm = this._formBuilder.group({
      email: [newItem.email, [Validators.required, Validators.email]],
      password: [''],
      usuario: [newItem.usuario, Validators.required],
      rol: [newItem.rol, Validators.required],
      socio: [newItem.id_socio],
      afiliado: [newItem.afiliado? true:false]
    });
    this.selectedrol = newItem.rol;
    this.addAction = true;
    this.divform = true;
    this.actions = false;
    this.titulo = '';
    this.file = [];
    this.archivo = '';
    if(newItem.rol=='Asociado'){
      this.getfile();
    }
  }

  add() {
    this.submitted = true;
    if (this.loginForm.status === "VALID") {
      this.addAction = true;
      this.loading = true;
      let set = {
        email: this.loginForm.controls.email.value,
        password: this.loginForm.controls.password.value || '',
        usuario: this.loginForm.controls.usuario.value,
        rol: this.loginForm.controls.rol.value,
        id_socio: '',
        afiliado: false
      };
      if(set.rol=='Asociado'){
        set.id_socio = this.loginForm.controls.socio.value;
        set.afiliado = this.loginForm.controls.afiliado.value;
      }
      this.usuarioService.add(set)
      .subscribe(
        res => {
          //console.log('HTTP response', res)
          Swal.fire('Se agrego correctamente.', '', 'success');
          this.divform = false;
          this.addAction = false;
          this.loading = false;
          this.submitted = false;
          this.addForm++;
          this.loginForm.reset();
        },err => {
          this.loading = false;
          this.submitted = false;
          //this.addAction = false;
          //console.log('HTTP Error', err)
        }
      );
     
    }
  }
  edit () {
    this.submitted = true;
    if (this.loginForm.status === "VALID") {
      this.actions = true;
      this.loading = true;
      let set = {
        id: this.idSelected,
        email: this.loginForm.controls.email.value,
        password: this.loginForm.controls.password.value || '',
        usuario: this.loginForm.controls.usuario.value,
        rol: this.loginForm.controls.rol.value,
        index: this.indexSelected,
        id_socio: '',
        afiliado: false
      };
      if(set.rol=='Asociado'){
        set.id_socio = this.loginForm.controls.socio.value;
        set.afiliado = this.loginForm.controls.afiliado.value;
      }
      this.usuarioService.edit(set)
      .subscribe(
        res => {
          //console.log('HTTP response', res)
          Swal.fire('Se edito correctamente.', '', 'success');
          this.divform = false;
          this.addAction = false;
          this.loading = false;
          this.submitted = false;
          this.idSelected = undefined;
          this.mostrardocumento = false;
          this.loginForm.reset();
          this.updateForm = set;
        },err => {
          this.loading = false;
          this.submitted = false;
          //this.addAction = false;
          //console.log('HTTP Error', err)
        });
    }
  }

  onFileSelected(event: any) {

    const imagen = event.target.files[0];
    console.log(imagen);
    if (imagen) {
      this.file = imagen;
      /*this.blobFile(imagen).then((res: any) => {
        this.imagenPrevia = res.base;

      })*/
    } else {
      console.log('No cargo nada');

    }
  }

  getfile(){
    this.usuarioService.getfile(this.idSelected)
      .subscribe((trae: any) => {
        
        if(trae.data){
          this.archivos = trae.data;
        }
        //console.log(this.archivos);
      });
  }

  addfile() {
    this.submitted2 = true;
    if(this.titulo=='')
      return;
    else if(this.archivo == '')
      return
    
    
      let set = {
        id: this.idSelected,
        file: this.file,
        titulo: this.titulo
      };
      
      //console.log(set);
      
      this.usuarioService.addfile(set)
      .subscribe(
        res => {
          //console.log('HTTP response', res)
          Swal.fire(res.message, '', 'success');
          this.loading2 = false;
          this.submitted2 = false;
          this.titulo = '';
          this.file = [];
          this.archivo = '';
          this.getfile();
        },err => {
          this.loading2 = false;
          
         
        });
 
  }

  deletefile(id) {
    
      
      this.usuarioService.deletefile(id)
      .subscribe((trae: any) => {
        Swal.fire('Se elimino correctamente.', '', 'success');
        this.getfile();
      });
    
  }

  verfile(r) {
    console.log(environment.apiUrl,r.ruta);
    //window.location.href=environment.apiUrl+'files/'+r.ruta;
    window.open(environment.apiUrl+'files/'+r.ruta, '_blank');
  }

  delete() {
    if (this.idSelected) {
      this.actions = true;
      this.loading = true;
      this.loginForm.reset();
      this.usuarioService.delete(this.idSelected)
      .subscribe((trae: any) => {
        Swal.fire('Se elimino correctamente.', '', 'success');
        this.deleteForm = {index: this.indexSelected, delForm: this.delForm++};
        this.idSelected = undefined;
        this.indexSelected = undefined;
        this.loading = false;
        this.addAction = false;
        this.divform = false;
      });
    }
  }
  resetAll() {
    this.actions = true;
    this.divform = false;
    this.addAction = false;
    this.loginForm.reset();
    this.idSelected = undefined;
    this.indexSelected = undefined;
  }
}
