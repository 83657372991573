import { Component, OnInit } from '@angular/core'
import { locale as en } from './i18n/en'
import { locale as fr } from './i18n/fr'
import { locale as de } from './i18n/de'
import { locale as pt } from './i18n/pt'

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { RecargosService } from "app/auth/service/recargos.service";
import  Swal  from 'sweetalert2'; 

import { CoreTranslationService } from '@core/services/translation.service'

@Component({
  selector: 'app-recargos',
  templateUrl: './recargos.component.html',
  styleUrls: ['./recargos.component.scss']
})
export class RecargosComponent implements OnInit {
  public contentHeader: object;
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';

  public addForm: number = 0;
  public updateForm: any;
  public delForm: number = 0;
  public deleteForm: any;

  divform: boolean = false;
  actions: boolean = true;
  addAction: boolean = false;
  idSelected!: number|undefined;
  indexSelected!: number|undefined;
   
  /**
   *
   * @param {CoreTranslationService} _coreTranslationService
   */
  constructor(
    private _coreTranslationService: CoreTranslationService,
    private _formBuilder: FormBuilder, 
    private recargosService: RecargosService) {
    this._coreTranslationService.translate(en, fr, de, pt)
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      seguro: ['', Validators.required],
      cuota_admin_b: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
      cuota_admin_f: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
      cuota_admin: ['', [Validators.required, Validators.min(0)]],
      cuota_admin_no_asociado: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
      capitalizacion: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
      deposito_deudas: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
      fondo_reposicion: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
      contribu_turismo: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
      ahorro: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
      gastos: ['', [Validators.required, Validators.min(0)]]
    });

    this.recargosService.get()
    .subscribe((trae: any) => {
      if(trae.data[0]){
        this.idSelected = trae.data[0]._id;
        this.loginForm = this._formBuilder.group({
          seguro: [trae.data[0].seguro, Validators.required],
          cuota_admin_b: [trae.data[0].cuota_admin_b, [Validators.required, Validators.min(0), Validators.max(100)]],
          cuota_admin_f: [trae.data[0].cuota_admin_f, [Validators.required, Validators.min(0), Validators.max(100)]],
          cuota_admin: [trae.data[0].cuota_admin, [Validators.required, Validators.min(0)]],
          cuota_admin_no_asociado: [trae.data[0].cuota_admin_no_asociado, [Validators.required, Validators.min(0), Validators.max(100)]],
          capitalizacion: [trae.data[0].capitalizacion, [Validators.required, Validators.min(0), Validators.max(100)]],
          deposito_deudas: [trae.data[0].deposito_deudas, [Validators.required, Validators.min(0), Validators.max(100)]],
          fondo_reposicion: [trae.data[0].fondo_reposicion, [Validators.required, Validators.min(0), Validators.max(100)]],
          contribu_turismo: [trae.data[0].contribu_turismo, [Validators.required, Validators.min(0), Validators.max(100)]],
          ahorro: [trae.data[0].ahorro, [Validators.required, Validators.min(0), Validators.max(100)]],
          gastos: [trae.data[0].gastos, [Validators.required, Validators.min(0)]]
        });
      }
    });

    this.contentHeader = {
      headerTitle: 'Recargos',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Inicio',
            isLink: true,
            link: '/'
          },
          {
            name: 'Recargos',
            isLink: false
          }
        ]
      }
    }
  }
  
  get f() {
    return this.loginForm.controls;
  }

  edit () {
    this.submitted = true;
    if (this.loginForm.status === "VALID") {
      this.actions = true;
      this.loading = true;
      let set = {
        id: this.idSelected,
        seguro: this.loginForm.controls.seguro.value,
        cuota_admin_b: this.loginForm.controls.cuota_admin_b.value,
        cuota_admin_f: this.loginForm.controls.cuota_admin_f.value,
        cuota_admin: this.loginForm.controls.cuota_admin.value,
        cuota_admin_no_asociado: this.loginForm.controls.cuota_admin_no_asociado.value,
        capitalizacion: this.loginForm.controls.capitalizacion.value,
        deposito_deudas: this.loginForm.controls.deposito_deudas.value,
        fondo_reposicion: this.loginForm.controls.fondo_reposicion.value,
        contribu_turismo: this.loginForm.controls.contribu_turismo.value,
        ahorro: this.loginForm.controls.ahorro.value,
        gastos: this.loginForm.controls.gastos.value
      };
      
      
      this.recargosService.edit(set)
      .subscribe(
        res => {
          //console.log('HTTP response', res)
          Swal.fire('Se edito correctamente.', '', 'success');
          this.loading = false;
        },err => {
          this.loading = false;
          this.submitted = false;
        });
    }
  }

}
