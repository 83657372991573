export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Inicio',
      VENTA: 'Ventas',
      ANUAL: 'Anual',
      AGENCIA: 'Agencia/vehículo',
      MANTENIMIENTO: 'Mantenimiento',
      RETENCION: 'Retención ICA',
      USUARIO: 'Usuarios'
    }
  }
}
