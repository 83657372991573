import { Component, Input, OnInit, SimpleChange, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { RetencionService } from "app/auth/service/retencion.service";

export interface RetencionElement {
  id?: number;
  ciudad: string;
  tarifa: string;
}

const ELEMENT_DATA: RetencionElement[] = [];

@Component({
  selector: 'app-tablelist2',
  templateUrl: './tablelist.component.html',
  styleUrls: ['./tablelist.component.scss']
})
export class TablelistComponent2 implements OnInit {
  @Output() EdititemEvent = new EventEmitter<any>();
  @Input() add: number|undefined;
  @Input() update: any;
  @Input() delete: any;

  total: number = 0;
  page: number = 0;
  pageindex: number = 0;
  numItems: string = '10';
  buscar: string = "";
  displayedColumns: string[] = ['ciudad', 'tarifa', "actions"];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  
  constructor(private retencionService: RetencionService) { }

  ngOnInit(): void {
    this.get();
  }
  ngOnChanges(changes: { [property: string]: SimpleChange }){
    //console.log(changes);
      if (changes.add && changes.add.currentValue) {
        this.get();
      } else if (changes.update && changes.update.currentValue !== undefined) {
        this.dataSource.data[Number(changes.update.currentValue.index)].ciudad = changes.update.currentValue.ciudad;
        this.dataSource.data[Number(changes.update.currentValue.index)].tarifa = changes.update.currentValue.tarifa;
        this.dataSource._updateChangeSubscription();
        this.update = {};
      } else if (changes.delete && changes.delete.currentValue !== undefined) {
        delete this.dataSource.data[Number(changes.delete.currentValue.index)];
        this.dataSource.data.splice(Number(changes.delete.currentValue.index), 1);
        this.dataSource._updateChangeSubscription();
        this.delete = {};
      }
  }
  pageSelect(page: any) {
    console.log(page);
    this.page = page.pageIndex;
    this.get();
  }
  get() {
    this.retencionService.get(this.page, this.numItems, this.buscar)
      .subscribe((trae: any) => {
       this.total = trae.total || 0;
       this.dataSource.data = trae.data;
       this.dataSource._updateChangeSubscription();
      });
  }

  applyFilter(event: Event) {
    //const filterValue = (event.target as HTMLInputElement).value;
    //this.dataSource.filter = filterValue.trim().toLowerCase();
    this.page=0;
    this.numItems='10';
    this.pageindex=0;
    setTimeout(() => { this.get(); }, 500);
  }
  addNewItem(value: any, index: number) {
    value.index = index;
    this.EdititemEvent.emit(value);
  }
}
