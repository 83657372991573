<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="bootstrap-select">
      <div class="row">
        <div class="col-xl-9 col-md-9 col-12" *ngIf="!divform"></div>
        <div class="col-xl-3 col-md-3 col-12 text-right" *ngIf="!divform">
          
        </div>
        <div class="col-xl-12 col-md-12 col-12" *ngIf="!divform">
          <div class="card ">
            <div class="card-body">
              <app-tablelist3 [add]="addForm" [update]="updateForm"  [delete]="deleteForm" (EdititemEvent)="ChooseItem($event)"></app-tablelist3>
            </div>
          </div>
        </div>

        <div class="col-xl-12 col-md-12 col-12" *ngIf="divform">
          <div class="card ">
            <div class="card-header">
              <!--<h4 class="card-title" *ngIf="actions">Agregar Nuevo Socio</h4>-->
              <h4 class="card-title" *ngIf="addAction">Editar Bus</h4>
            </div>
            <div class="card-body">

              <form class="auth-login-form mt-2" [formGroup]="loginForm">
                <div class="row">
                  <div class="col-sm-5 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-ciudad">Nombre</label>
                      <input
                        type="text"
                        formControlName="nombre"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }"
                        placeholder=""
                        aria-describedby="form-nombre"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                        <div *ngIf="f.nombre.errors.required">Nombre obligatorio</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-ciudad">Cod.Contable</label>
                      <input
                        type="text"
                        formControlName="contable"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.contable.errors }"
                        placeholder=""
                        aria-describedby="form-contable"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.contable.errors" class="invalid-feedback">
                        <div *ngIf="f.contable.errors.required">Cod.Contable obligatorio</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-ciudad">Matricula</label>
                      <input
                        type="text"
                        formControlName="matricula"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.matricula.errors }"
                        placeholder=""
                        aria-describedby="form-matricula"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.matricula.errors" class="invalid-feedback">
                        <div *ngIf="f.matricula.errors.required">Matricula obligatoria</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-documento">Butacas</label>
                      <input
                        type="number"
                        min="0" 
                        formControlName="butacas"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.butacas.errors }"
                        placeholder=""
                        aria-describedby="form-butacas"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.butacas.errors" class="invalid-feedback">
                        <div *ngIf="f.butacas.errors.required">Butacas oligatoria</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-ciudad">Carroceria</label>
                      <input
                        type="text"
                        formControlName="carroceria"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.carroceria.errors }"
                        placeholder=""
                        aria-describedby="form-carroceria"
                        autofocus=""
                        tabindex="1"
                      />
                      <div *ngIf="submitted && f.carroceria.errors" class="invalid-feedback">
                        <div *ngIf="f.carroceria.errors.required">Carroceria obligatoria</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                  
                    <div class="form-group">
                      <label class="form-label" for="form-ciudad">Socio</label>
                      <ng-select [items]="socios" formControlName="id_socio" bindValue="SocioID" bindLabel="Nombre" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.id_socio.errors }"> </ng-select>

                      <div *ngIf="submitted && f.id_socio.errors" class="invalid-feedback">
                        <div *ngIf="f.id_socio.errors.required">Socio obligatorio</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                  
                    <div class="form-group">
                      <label class="form-label">Tipo Servicio</label>
                      <select formControlName="tipo"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.tipo.errors }">
                        <option *ngFor="let t of tipos" [value]="t.Nombre">{{t.Nombre}}</option>
                      </select>
                      <div *ngIf="submitted && f.tipo.errors" class="invalid-feedback">
                        <div *ngIf="f.tipo.errors.required">Tipo Servicio obligatorio</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2 col-12" *ngIf="addAction">
                    <br>
                    <button [disabled]="loading" class="btn btn-info btn-block" tabindex="4" rippleEffect (click)="edit()">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Editar 
                    </button>
                  </div>
                  <div class="col-sm-2 col-12">
                    <br>
                    <button [disabled]="loading" class="btn btn-secondary btn-block" tabindex="4" rippleEffect (click)="resetAll()">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Cancelar 
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
