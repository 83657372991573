import { Component, OnInit } from '@angular/core'
import { HomeService } from "app/auth/service/home.service";
import { User } from 'app/auth/models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  tot_v: number;
  tot_a: number;
  tot_ag: number;
  tot_c: number;
  public currentUser: User;

  constructor(private homeService: HomeService) {}

  public contentHeader: object

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.tot_v = 0;
    this.tot_a = 0;
    this.tot_ag = 0;
    this.tot_c = 0;

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let rol = this.currentUser.rol || '';
    //console.log(this.currentUser);
    let id_socio = this.currentUser.id_socio || '';
    this.homeService.get({rol,id_socio})
    .subscribe((trae: any) => {
      //console.log(trae);
      if(trae['cont_1'])
        this.tot_v = trae['cont_1'];
      //if(trae['cont_2'])
      //  this.tot_a = trae['cont_2'];
      if(trae['cont_3'])
        this.tot_ag = trae['cont_3'];
      if(trae['cont_4'])
        this.tot_c = trae['cont_4'];
    });

    this.contentHeader = {
      headerTitle: 'Home',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Sample',
            isLink: false
          }
        ]
      }
    }
  }
}
