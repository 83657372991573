<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="bootstrap-select">
      <div class="row">
        <div class="col-xl-12 col-md-12 col-12" >
          <div class="card ">
            <div class="card-body">
              <div class="row">
                  <div class="col-sm-12 col-12 row"  >
                    <div class="col-12" >
                      <hr>
                      <h4 class="card-title">Documentos</h4>
                    </div>
                    <div class="col-12 row">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Titulo</th>
                              <th>Nombre del Documento</th>
                              <th>Fecha subida</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let arc of archivos">
                              <td>{{arc.titulo}}</td>
                              <td>{{arc.nombre_archivo || ''}}</td>
                              <td>{{arc.creado || ''}}</td>
                              <td>
                                <button class="btn btn-info btn-block" tabindex="4" rippleEffect (click)="verfile(arc)">
                                  Descargar
                                </button>
                              </td>
                            </tr>
                            <tr *ngIf="archivos.length==0">
                              <td colspan="4" style="text-align:center;">Sin documentos cargados</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
