import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { BehaviorSubject, throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment'; 

@Injectable({
  providedIn: 'root'
})
export class ReportesService {
  apiUrl: String;
  constructor(
    private _http: HttpClient,
  ) {
    this.apiUrl = environment.apiUrl;
  }
  getreporteventas(param: Object): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/reporte/ventas/', { params: new HttpParams().set('agencia',param['agencia']).set('localidad',param['localidad']).set('mes',param['mes']).set('anio',param['anio']).set('desde',param['desde']).set('hasta',param['hasta']).set('tipo',param['tipo'])});
  }
  getreporteproducidos(param: Object): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/reporte/producidos/', { params: new HttpParams().set('agencia',param['agencia']).set('localidad',param['localidad']).set('mes',param['mes']).set('anio',param['anio']).set('coche',param['coche']).set('id_socio',param['id_socio'])});
  }
  getreportemensual(param: Object): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/reporte/mensual/', { params: new HttpParams().set('agencia',param['agencia']).set('cierre',param['cierre']).set('inicial',param['inicial']).set('mes',param['mes']).set('anio',param['anio']).set('desde',param['desde']).set('hasta',param['hasta']).set('tipo',param['tipo'])});
  }
  getfinanio(param: Object): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/reporte/finanio/', { params: new HttpParams().set('agencia',param['agencia']).set('coche',param['coche']).set('localidad',param['localidad']).set('propietario',param['propietario']).set('mes',param['mes']).set('anio',param['anio'])});
  }
  getreporteagencia(param: Object): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/reporte/agencia/', { params: new HttpParams().set('agencia',param['agencia']).set('coche',param['coche']).set('localidad',param['localidad']).set('mes',param['mes']).set('anio',param['anio']).set('id_socio',param['id_socio']).set('desde',param['desde']).set('hasta',param['hasta']).set('tipo',param['tipo']).set('busqueda',param['busqueda'])});
  }
  getreportebusdetallado(param: Object): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/reporte/vehiculoDetallado/', { params: new HttpParams().set('coche',param['coche']).set('mes',param['mes']).set('anio',param['anio']).set('id_socio',param['id_socio']).set('desde',param['desde']).set('hasta',param['hasta']).set('tipo',param['tipo'])});
  }
  getreporteanticipo(param: Object): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/reporte/anticipo/', { params: new HttpParams().set('agencia',param['agencia']).set('coche',param['coche']).set('mes',param['mes']).set('anio',param['anio']).set('desde',param['desde']).set('hasta',param['hasta']).set('tipo',param['tipo'])});
  }
  getreporteegresos(param: Object): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/reporte/egreso/', { params: new HttpParams().set('agencia',param['agencia']).set('coche',param['coche']).set('mes',param['mes']).set('anio',param['anio']).set('desde',param['desde']).set('hasta',param['hasta']).set('tipo',param['tipo'])});
  }
  getreportetiquetes(param: Object): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/reporte/tiquetes/', { params: new HttpParams().set('buscar',param['buscar']).set('fecha',param['fecha']).set('agencia',param['agencia'])});
  }
  getreportediario(param: Object): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/reporte/diario/', { params: new HttpParams().set('agencia',param['agencia']).set('inicial',param['inicial']).set('fecha',param['fecha'])});
  }
  getreporteingresos(param: Object): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/reporte/ingresos/', { params: new HttpParams().set('agencia',param['agencia']).set('coche',param['coche']).set('localidad',param['localidad']).set('mes',param['mes']).set('anio',param['anio']).set('desde',param['desde']).set('hasta',param['hasta']).set('tipo',param['tipo']).set('reporte',param['reporte'])});
  }

  getAgencias(): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/agencias/', {});
  }
  getLocalidad(): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/localidades/', {});
  }
  getAgenciasbyciudad(param: Object): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/agenciasbyciudad/', { params: new HttpParams().set('localidad',param['localidad'])});
  }
  getCoches(): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/coches/', {});
  }
  getCochesbyasociado(param: Object): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/cochesbyasociado/', { params: new HttpParams().set('id_s',param['id_s'])});
  }
  getSocios(): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/socios/', {});
  }
  getIca(): Observable<any> {
    return this._http.get<any>(this.apiUrl + '/retencion/getall', {});
  }
}
