import { Component, OnInit } from '@angular/core'
import { TxtService } from "app/auth/service/txt.service";
import { ReportesService } from "app/auth/service/reportes.service";
import {formatCurrency, getCurrencySymbol} from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { locale as en } from '../i18n/en'
import { locale as fr } from '../i18n/fr'
import { locale as de } from '../i18n/de'
import { locale as pt } from '../i18n/pt'

import { CoreTranslationService } from '@core/services/translation.service'
import  Swal  from 'sweetalert2';

export interface Tipo {
  Nombre: string;
}
export interface Agencia {
  Id: number;
  Nombre: string;
  Codigo: string;
}

@Component({
  selector: 'app-txtPlano',
  templateUrl: './txtPlano.component.html',
  styleUrls: ['./txtPlano.component.scss']
})
export class TxtPlanoComponent implements OnInit {
  public contentHeader: object
  public form: FormGroup;
  public loading = false;
  
  btnAction: boolean = false;
  displayedColumns: string[] = ['id', 'sucursal', 'cod', 'desde', 'fechaDesde', 'fechaHasta', 'totalD', 'totalC', 'diferencia', 'fecha', 'usuario', "actions"];
  dataSource: any = [];
  tipo_bus: Tipo[] = [{Nombre: 'TIQUETES'},{Nombre: 'DESPACHO VEHICULOS'},{Nombre: 'ANTICIPOS'},{Nombre: 'EGRESOS'}];
  selectedtipo: string; 
  selectedDesde: string;
  selectedHasta: string;
  tot_pro: number;
  txt_btn: string;
  agencias: Agencia[] = [];
  selectBasicLoading = false;
  
  /**
   *
   * @param {CoreTranslationService} _coreTranslationService
   */
  constructor(private _coreTranslationService: CoreTranslationService, private txtService: TxtService, private _formBuilder: FormBuilder, private reportesService: ReportesService) {
    this._coreTranslationService.translate(en, fr, de, pt)
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.tot_pro = 0;
    this.txt_btn = 'Generar';
    this.selectBasicLoading = true;

    this.form = this._formBuilder.group({
      tipo: ['', [Validators.required]],
      desde: ['', Validators.required],
      hasta: ['', Validators.required],
      tipoSucursal: ['OPERATIVA', Validators.required],
      sucursal: [''],
      comprobante: ['', Validators.required],
      nrDesde: ['', Validators.required]
    });

    this.reportesService.getAgencias()
      .subscribe((trae: any) => {
        //console.log(trae);
        this.selectBasicLoading = false;
        if(trae.data[0]){
          this.agencias = trae.data[0];
          this.agencias.unshift({Id: 0, Nombre: 'Todas', Codigo: '070'});
          this.form.patchValue({sucursal:'0'})
        }
      });

    this.contentHeader = {
      headerTitle: 'Archivos Planos',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Inicio',
            isLink: true,
            link: '/'
          },
          {
            name: 'Archivos Planos',
            isLink: false
          }
        ]
      }
    }
  }

  formato(val) {
    return formatCurrency(val, 'en-US', '', 'CLP');
  }

  buscar(){
    let valor = this.form.controls.tipo.value;
    this.obtener(valor);
  }

  obtener(valor: string = '') {
    this.dataSource = [];
    if(valor == ''){
      return;
    }
    this.txtService.getAll(valor)
      .subscribe((trae: any) => {
        //console.log(trae);
        if(trae.data){          
          this.dataSource = trae.data;
        }
     });
  }

  updateSelect(v){
    let sucursal: any = this.agencias.filter(n => n.Id ==  v.Id);
    this.form.patchValue({comprobante:'L'+sucursal[0].Codigo.trim()});
  }

  generar() {
    if (this.form.status === "VALID") {
      this.loading = true;
      let user = JSON.parse(localStorage.getItem('currentUser'));
      let nombreSucursal: any = this.agencias.filter(n => n.Id ==  this.form.controls.sucursal.value);
      let data = {
        ...this.form.value,
        usuario: user.usuario,
        sucursalTxt: nombreSucursal[0].Nombre || ''
      }
      this.txtService.add(data)
      .subscribe(
        res => {
          Swal.fire('Se agrego correctamente.', '', 'success');
          this.loading = false;
          let valor = this.form.controls.tipo.value;
          this.obtener(valor);
        },err => {
          this.loading = false;
        }
      );
    }else {
      Swal.fire('Debes completar los datos', '', 'error');
    }
  }

  descargarTxt(v){
    const a = document.createElement("a");
    const archivo = new Blob([atob(v.contenido)], { type: 'text/plain' });
    const url = URL.createObjectURL(archivo);
    a.href = url;
    a.download = v.comprobante+''+v.nrDesde;
    a.click();
    URL.revokeObjectURL(url);
  }

  deleteTxt(id) {
      this.txtService.delete(id)
      .subscribe((trae: any) => {
        Swal.fire('Se elimino correctamente.', '', 'success');
        let valor = this.form.controls.tipo.value;
        this.obtener(valor);
      });
    
  }

}
