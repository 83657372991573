import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment'; 
import  Swal  from 'sweetalert2'; 

@Injectable({
  providedIn: 'root'
})
export class VehiculosService {
  apiUrl: String;
  constructor(
    private _http: HttpClient,
  ) {
    this.apiUrl = environment.apiUrl+ "/vehiculos/";
  }
  get(pagina: number, items: string, buscar: string): Observable<any> {
    return this._http.get<any>(this.apiUrl + 'get/', { params: new HttpParams().set('pagina',pagina).set('items',items).set('buscar',buscar)});
  }
  edit(params: Object): Observable<any> {
    return this._http.put<any>(this.apiUrl + 'update', params).pipe(
      map((res: any)=>{
        return res;
      }),
      catchError((err) => this.handlerError(err))
    );
  }

  private handlerError(err): Observable<never> {
    //console.log(err)
    let errorMessage = 'ha ocurrido un error';
    if (err) {
      errorMessage = `${err.error.error}`;
    }
    Swal.fire(errorMessage, '', 'error');
    return throwError(errorMessage);
  }
}
