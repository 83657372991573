<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="bootstrap-select">
      <div class="row">
        <div class="col-12">
          
            <div class="row">
            <div class="col-sm-2 col-12">
              <label>Ciudad</label>
              <div class="form-group">
                <ng-select [items]="ciudades" name="ciudad" [(ngModel)]="selectedCiudad" bindValue="id" bindLabel="Nombre" [loading]="selectBasicLoading" class="form-control"> </ng-select>
              </div>
              
            </div>
            <div class="col-sm-2 col-12">
              <label>Buses</label>
              <div class="form-group">
                <ng-select [items]="coches" name="coches" [(ngModel)]="selectedCoche" bindValue="id" bindLabel="Nombre" [loading]="selectBasicLoading3" class="form-control"> </ng-select>
              </div>
              
            </div>

            

            <div class="col-sm-2 col-12">
              <label>Mes</label>
              <div class="form-group">
                <ng-select [items]="meses" name="mes" [(ngModel)]="selectedMes" bindValue="id" bindLabel="Nombre" class="form-control"> </ng-select>
              </div>
            </div>
            <div class="col-sm-2 col-12" >
              <label>Año</label>
              <div class="form-group">
                <ng-select [items]="anios" name="anios" [(ngModel)]="selectedAnio" bindValue="valor" bindLabel="valor" class="form-control"> </ng-select>
              </div>
            </div>

            

            <div class="col-sm-2 col-12 text-center">
              <button (click)="buscar()" class="btn btn-primary mt-2" rippleEffect>{{txt_btn}}</button>
            </div>
          </div>
        
          <div class="col-sm-12 col-12 text-right" *ngIf="btnDescargas">
            <hr>
              <button (click)="exportexcel()" class="btn btn-success " rippleEffect>Descargar por Excel</button>
              <button (click)="openPDF()" class="btn btn-success ml-1" rippleEffect>Descargar por PDF</button>
              <button (click)="exporter.exportTable('txt', {fileName:'Excel Reporte Producido', delimiter:','})" class="btn btn-success ml-1" rippleEffect>Descargar por TXT</button>
            <hr>
              
            </div>
          </div>
          <div class="col-12 table-responsive">
            <table mat-table matTableExporter  [dataSource]="dataSource" class="table" id="excel-table" #exporter="matTableExporter">

              <ng-container matColumnDef="Placa">
                <th mat-header-cell *matHeaderCellDef> Placa. </th>
                <td mat-cell *matCellDef="let element"> {{element.Placa}} </td>
              </ng-container>

              <ng-container matColumnDef="Nombre_B">
                <th mat-header-cell *matHeaderCellDef> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.Nombre_B}} </td>
              </ng-container>

              <ng-container matColumnDef="Nombre_S">
                <th mat-header-cell *matHeaderCellDef> Socio Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.Nombre_S}} </td>
              </ng-container>

              <ng-container matColumnDef="Apellido_S">
                <th mat-header-cell *matHeaderCellDef> Socio Apellido </th>
                <td mat-cell *matCellDef="let element"> {{element.Apellido_S}} </td>
              </ng-container>

              <ng-container matColumnDef="Documento_S">
                <th mat-header-cell *matHeaderCellDef> Socio Documento </th>
                <td mat-cell *matCellDef="let element"> {{element.Documento_S}} </td>
              </ng-container>

              <ng-container matColumnDef="Despacho">
                <th mat-header-cell *matHeaderCellDef> Producido </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.Despacho-(element.Numero_Pasajeros*seguro))}} </td>
              </ng-container>

              <ng-container matColumnDef="Numero_Pasajeros">
                <th mat-header-cell *matHeaderCellDef> Número Pasajero </th>
                <td mat-cell *matCellDef="let element"> {{element.Numero_Pasajeros}} </td>
              </ng-container>

              <ng-container matColumnDef="seguro">
                <th mat-header-cell *matHeaderCellDef> Valor de Seguro </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.Numero_Pasajeros*seguro)}} </td>
              </ng-container>

              <ng-container matColumnDef="producido">
                <th mat-header-cell *matHeaderCellDef> Despacho </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.Despacho)}} </td>
              </ng-container>

              <ng-container matColumnDef="anticipos">
                <th mat-header-cell *matHeaderCellDef> Anticipos </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.anticipos || 0)}} </td>
              </ng-container>

              <ng-container matColumnDef="cuota">
                <th mat-header-cell *matHeaderCellDef> Cuota de administración </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.cuota || 0)}} </td>
              </ng-container>

              <ng-container matColumnDef="capitalizacion">
                <th mat-header-cell *matHeaderCellDef> Capitalización </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.capitalizacion || 0)}} </td>
              </ng-container>

              <ng-container matColumnDef="reposicion">
                <th mat-header-cell *matHeaderCellDef> Fondo de reposición </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.reposicion || 0)}} </td>
              </ng-container>

              <ng-container matColumnDef="turismo">
                <th mat-header-cell *matHeaderCellDef> Contribución al turismo </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.turismo || 0)}} </td>
              </ng-container>

              <ng-container matColumnDef="ahorro">
                <th mat-header-cell *matHeaderCellDef> Ahorro </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.ahorro || 0)}} </td>
              </ng-container>

              <ng-container matColumnDef="gastos">
                <th mat-header-cell *matHeaderCellDef> Gastos publicitarios </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.gastos || 0)}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
