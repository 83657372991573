<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <div class="row match-height" >
      

      <!-- Statistics Card -->
      <div class="col-xl-12 col-md-12 col-12">
        <div class="card card-statistics">
          <div class="card-header">
            <h4 class="card-title">Contadores</h4>
          </div>
          <div class="card-body statistics-body">
            <div class="row">
              <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                <div class="media">
                  <div class="avatar bg-light-primary mr-2">
                    <div class="avatar-content">
                      <i data-feather="map-pin" class="avatar-icon"></i>
                    </div>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="font-weight-bolder mb-0">{{tot_v}}</h4>
                    <p class="card-text font-small-3 mb-0">Viajes de hoy</p>
                  </div>
                </div>
              </div>
              <!--<div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                <div class="media">
                  <div class="avatar bg-light-info mr-2">
                    <div class="avatar-content">
                      <i data-feather="user" class="avatar-icon"></i>
                    </div>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="font-weight-bolder mb-0">{{tot_a}}</h4>
                    <p class="card-text font-small-3 mb-0">Admin</p>
                  </div>
                </div>
              </div>-->
              <div class="col-sm-6 col-xl-3 mb-2 mb-sm-0" *ngIf="currentUser.rol!='Asociado'">
                <div class="media">
                  <div class="avatar bg-light-danger mr-2">
                    <div class="avatar-content">
                      <i data-feather="home" class="avatar-icon"></i>
                    </div>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="font-weight-bolder mb-0">{{tot_ag}}</h4>
                    <p class="card-text font-small-3 mb-0">Agencias</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="media">
                  <div class="avatar bg-light-success mr-2">
                    <div class="avatar-content">
                      <i data-feather="truck" class="avatar-icon"></i>
                    </div>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="font-weight-bolder mb-0">{{tot_c}}</h4>
                    <p class="card-text font-small-3 mb-0">Buses</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-statistics">
          <img class="card-img-top" src="assets/images/banner/slide_n1_b.jpg" alt="Image" />
        </div>
      <!--/ Statistics Card -->
    </div>
  </div>
</div>
