<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="bootstrap-select">
      <div class="row">
        <div class="col-12">
          
            <div class="row">
            <div class="col-sm-3 col-12">
              <label>Agencias</label>
              <div class="form-group">
                <ng-select [items]="agencias" name="agencias" [(ngModel)]="selectedAgencia" bindValue="Id" bindLabel="Nombre" [loading]="selectBasicLoading" class="form-control"> </ng-select>
              </div>
              
            </div>
            <div class="col-sm-2 col-12">
              <label>Tipo Filtro</label>
              <div class="form-group">
                <select name="tipo" class="form-control" [(ngModel)]="selectedtipo">
                  <option *ngFor="let tipo of tipo_bus" [value]="tipo.Nombre">{{tipo.Nombre}}</option>
                </select>
              </div>
            </div>

            <div class="col-sm-2 col-12" *ngIf="selectedtipo=='Mensual'">
              <label>Mes</label>
              <div class="form-group">
                <ng-select [items]="meses" name="mes" [(ngModel)]="selectedMes" bindValue="id" bindLabel="Nombre" class="form-control"> </ng-select>
              </div>
            </div>
            <div class="col-sm-2 col-12" *ngIf="selectedtipo=='Mensual'">
              <label>Año</label>
              <div class="form-group">
                <ng-select [items]="anios" name="anios" [(ngModel)]="selectedAnio" bindValue="valor" bindLabel="valor" class="form-control"> </ng-select>
              </div>
            </div>

            <div class="col-sm-2 col-12" *ngIf="selectedtipo=='Rango'">
              <label>Fecha Inicial</label>
              <div class="form-group">
                <input type="date" [(ngModel)]="selectedDesde" class="form-control"/>
              </div>
            </div>
            <div class="col-sm-2 col-12" *ngIf="selectedtipo=='Rango'">
              <label>Fecha Fin</label>
              <div class="form-group">
                <input type="date" [(ngModel)]="selectedHasta" class="form-control"/>
              </div>
            </div>
            <div class="col-sm-2 col-12 text-center">
              <button (click)="buscar()" class="btn btn-primary mt-2" rippleEffect>{{txt_btn}}</button>
            </div>
          </div>
        
          <div class="col-sm-12 col-12 text-right" *ngIf="btnDescargas">
            <hr>
              <button (click)="exportexcel()" class="btn btn-success " rippleEffect>Descargar por Excel</button>
              <button (click)="openPDF()" class="btn btn-success ml-1" rippleEffect>Descargar por PDF</button>
              <button (click)="exporter.exportTable('txt', {fileName:'Excel ingresos agencias', delimiter:','})" class="btn btn-success ml-1" rippleEffect>Descargar por TXT</button>
            <hr>
              
            </div>
          </div>
          <div class="col-12 table-responsive">
            <table mat-table matTableExporter  [dataSource]="dataSource" class="table" id="excel-table" #exporter="matTableExporter" >

              <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef> Agencia </th>
                <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                <td mat-footer-cell *matFooterCellDef> </td>
              </ng-container>

              <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef> Fecha </th>
                <td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
                <td mat-footer-cell *matFooterCellDef> <b>Total</b></td>
              </ng-container>

              <ng-container matColumnDef="producido">
                <th mat-header-cell *matHeaderCellDef> Monto </th>
                <td mat-cell *matCellDef="let element"> {{formato(element.importeOperacion)}} </td>
                <td mat-footer-cell *matFooterCellDef> <b>{{formato(tot_pro)}}</b> </td>
              </ng-container> 

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </table>
           
          </div>
        
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
