<div class="row">
  <div class="col-sm-12 col-12">
    <div class="form-group">
      <label class="form-label" for="form-ciudad">Filtro</label>
      <input type="text" class="form-control" (keyup)="applyFilter($event)" [(ngModel)]="buscar" placeholder="Ej. bus"/>
    </div>
  </div>
</div>

<table mat-table [dataSource]="dataSource" class="table">

  <!-- Name Column -->
  <ng-container matColumnDef="descripcion">
    <th mat-header-cell *matHeaderCellDef> Descripción </th>
    <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
  </ng-container>

  <ng-container matColumnDef="fecha">
    <th mat-header-cell *matHeaderCellDef> Fecha </th>
    <td mat-cell *matCellDef="let element"> {{formatofecha(element.fecha)}} </td>
  </ng-container>

  <ng-container matColumnDef="valor">
    <th mat-header-cell *matHeaderCellDef> Monto </th>
    <td mat-cell *matCellDef="let element"> {{element.valor}} </td>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Accion </th>
    <td mat-cell *matCellDef="let element; let i = index;">
      <button class="btn btn-primary btn-block" tabindex="4" rippleEffect (click)="addNewItem(element, i)">
        Ver 
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro "{{buscar}}"</td>
  </tr>
</table>
<mat-paginator [length]="total"  [pageIndex]="pageindex" [pageSize]="10"  [pageSizeOptions]="[10]" [hidePageSize]="true" aria-label="Select page" (page)="pageSelect($event)">
</mat-paginator>